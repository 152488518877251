import React from "react";
import { Box, Menu, MenuItem, Paper, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import "./dashboard.scss";
import cardImg from "../../Assets/images/formCardImg.png";
import { covertISOToIndian } from "../../utils";
import { useNavigate } from "react-router-dom";

const FormCard = ({
  form,
  onClick,
  onResponseClick,
  setFormID,
  setCopyFormModal,
  setEditFormModal,
  editForm,
  setShowCoeditorModal,
}) => {
  function addIterator(str) {
    if (str.length > 12) {
      str = str.slice(0, 12) + "...";
    }
    return str;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Paper onClick={onClick} sx={{ background: "#FFF4EF" }} className="formCard" elevation={3}>
        <Box className="cardTopBar">
          <Box display={"flex"} alignItems={"center"} gap={"0.255rem"} className="">
            <DescriptionIcon fontSize="small" color="primary" />
            <Typography fontWeight={600}>{addIterator(form?.form_title)}</Typography>
          </Box>
          <MoreVertSharpIcon
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          />
        </Box>
        <img className="cardImg" src={cardImg} alt="cardImg" />
        <Box className="dateContainer">
          <Typography className="dateFont">
            Last opened: <br /> {covertISOToIndian(form?.last_opened)}
          </Typography>
        </Box>
      </Paper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {form.is_active && (
          <>
            <MenuItem
              onClick={(e) => {
                // onResponseClick();
                e.stopPropagation();
                setEditFormModal((prev) => ({
                  ...prev,
                  modal: true,
                  formID: form?._id,
                  title: form?.form_title,
                }));
                handleClose();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setFormID(form?._id);
                setCopyFormModal(true);
                handleClose();
              }}
            >
              Create a copy
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            if (form?.is_active) {
              editForm("inactive", form?._id);
            } else {
              editForm("active", form?._id);
            }
            handleClose();
          }}
        >
          {form?.is_active ? "Deactivate" : "Activate"} form
        </MenuItem>
        {form.is_active && (
          <>
            <MenuItem
              onClick={() => {
                onResponseClick();
                handleClose();
              }}
            >
              Download responses
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowCoeditorModal((prev) => ({
                  ...prev,
                  open: true,
                  form_id: form?._id,
                }));
                handleClose();
              }}
            >
              Add co-editor
            </MenuItem>
            <MenuItem onClick={() => navigate(`/setting/${form?.form_slug}`)}>Setting</MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

export default FormCard;
