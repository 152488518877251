import {
  ADD_NEW_COMPONENT,
  ADD_NEW_SECTION,
  COPY_COMPONENT,
  FORM_CONFIG_FAIL,
  FORM_CONFIG_RESET,
  FORM_CONFIG_SAVE,
  FORM_CONFIG_UPDATE,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REMOVE_COMPONENT,
  UPDATE_SECTION_SECTION,
} from "./actionTypes";

import { QUESTION_INPUT } from "../config/Constants";

export const formConfigReducer = (state = {}, action) => {
  let payload = action.payload;
  switch (action.type) {
    case FORM_CONFIG_SAVE:
      return action.payload;
    case FORM_CONFIG_UPDATE:
      let { form_title, form_banner } = action.payload;
      return {
        ...state,
        form_banner: form_banner,
        form_title: form_title,
      };

    case FORM_CONFIG_FAIL:
      return { error: action.payload };

    case FORM_CONFIG_RESET:
      return {};

    case ADD_NEW_SECTION:
      return {
        ...state,
        section: [...state.section, payload],
      };
    case UPDATE_SECTION_SECTION:
      return {
        ...state,
        section: state.section.map((item) => {
          if (item?.section_title === "") {
            return {
              ...item,
              _id: payload._id,
              section_title: payload.section_title,
              section_description: payload.section_description,
            };
          } else if (item._id === payload._id) {
            return {
              ...item,
              section_title: payload.section_title,
              section_description: payload.section_description,
            };
          } else return item;
        }),
      };
    // break;
    case ADD_NEW_COMPONENT:
      return {
        ...state,
        section: state.section.map((section) => {
          if (section._id === payload.section_id) {
            const newComponentData = {
              form_id: payload.form_id,
              section_id: payload.section_id,
              type: payload.type,
              order: payload.newComponentOrder + 1,
              newComponent: true,
              title: "",
              description: "",
              media_url: "",
              question: {
                qTitle: "",
                qType: QUESTION_INPUT,
                options: [],
              },
            };

            let insertIndex = 0;
            if (section?.component) {
              // Find the index where the new object should be inserted
              insertIndex =
                section?.component.findIndex((item) => item?.order === payload?.newComponentOrder) + 1;

              // Insert the newComponentData object at the found index
              section?.component?.splice(insertIndex, 0, newComponentData);
            } else {
              section.component = [];
              section?.component?.splice(insertIndex, 0, newComponentData);
            }
          }
          return section;
        }),
      };
    case COPY_COMPONENT:
      return {
        ...state,
        section: state.section.map((section) => {
          if (section._id === payload.section_id) {
            let oldOrder = payload.order;
            delete payload._id;

            let newComponentData = {
              ...payload,
              order: oldOrder + 1,
              newComponent: true, // to keep `save` button enabled.
            };

            // Find the index of the component with the old order
            let oldComponentIndex = section?.component?.findIndex(
              (component) => component?.order === oldOrder,
            );

            // Insert the newComponentData object after the component with the old order
            section.component.splice(oldComponentIndex + 1, 0, newComponentData);
          }
          return section;
        }),
      };
    case REMOVE_COMPONENT:
      return {
        ...state,
        section: state.section.map((section) => {
          if (section._id === payload.section_id) {
            // Filter out the component with the specified order
            const newComponents = section.component.filter(
              (comp) => comp.order !== payload.componentOrder,
            );
            return {
              ...section,
              component: newComponents,
            };
          }
          return section; // Moved outside of the if block
        }),
      };
    default:
      return state;
  }
};

export const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return action.payload;

    case LOGIN_FAIL:
      return { loginError: action.payload };

    case LOGOUT:
      return {};

    default:
      return state;
  }
};
