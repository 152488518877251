import React from "react";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import ComponentContainer from "../../CreateForm/ComponentContainer";
import "../../CreateForm/Form.scss";

const ImagePreview = ({ img_url }) => {
  return (
    <ComponentContainer>
      <img className="formImagePreview" src={`${cloudfrontUrl}/${img_url}`} alt={img_url} />
    </ComponentContainer>
  );
};

export default ImagePreview;
