import React from "react";
import { Skeleton } from "@mui/material";

const SkeletonLoader = ({ count = 1, width = 100, height = 40 }) => {
  return (
    <div>
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={`${width}%`}
          height={height}
          sx={{ mb: 1 }}
        />
      ))}
    </div>
  );
};

export default SkeletonLoader;
