import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  TextField,
  Container,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { country_and_states } from "../../utils/country_and_states"; // Ensure this import is correct
import { useSelector } from "react-redux";
import { POST } from "../../config/Api";
import { Constants } from "../../config/Constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  uniqueId: yup.string().required("Unique ID is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  name: yup.string().required("Name is required"),
  organization: yup.string().required("Organization is required"),
  state: yup.string().required("State is required"),
  country: yup.string().required("Country is required"),
  purpose: yup.string().required("Purpose is required"),
});

export default function RequestForCreatorModal({ open, setOpen }) {
  const { user } = useSelector((state) => state.userLoginDetails);

  const formik = useFormik({
    initialValues: {
      uniqueId: "",
      email: "",
      name: "",
      organization: "",
      state: "",
      country: "",
      purpose: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          user_id: user.id,
          name: values.name,
          organization: values.organization,
          country: country_and_states.country[values.country], // Get full country name
          state: country_and_states.states[values.country].find(
            (state) => state.code === values.state,
          ).name, // Get full state name
          purpose: values.purpose,
        };
        await requestForFormCreator(payload);

        setOpen(false);
      } catch (error) {
        console.error("Error submitting form:", error);
        // Handle error (e.g., show error message to user)
      }
    },
  });

  useEffect(() => {
    if (open && user) {
      const userCountry = Object.keys(country_and_states.country).find(
        (key) => country_and_states.country[key] === user.country,
      );
      const userState = country_and_states.states[userCountry]?.find(
        (state) => state.name === user.state,
      )?.code;

      formik.setValues({
        uniqueId: user.email || "",
        email: user.reset_email || "",
        name: user.name || "",
        organization: user.organization || "",
        state: userState || "",
        country: userCountry || "",
        purpose: "",
      });
    }
  }, [open, user]);

  const handleClose = () => {
    setOpen(false);
  };

  const requestForFormCreator = async (payload) => {
    try {
      const response = await POST(`${Constants.form}/form_creator_request`, payload);
      return response;
    } catch (error) {
      throw error; // Rethrow the error so it can be caught in the form submission
    }
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    formik.setFieldValue("country", country);
    formik.setFieldValue("state", ""); // Reset state when country changes
  };

  const states = country_and_states.states[formik.values.country] || [];
  const isStateDisabled = !formik.values.country;

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar
        sx={{
          backgroundImage:
            "linear-gradient(90deg, #141415 0, rgb(0, 75, 87) 40%, rgb(0, 75, 87)70%)",
          position: "sticky",
          top: 0,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1, color: "#fff" }} variant="h6" component="div">
            Request for Form Creator
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ border: "1px solid #fff", borderRadius: "5px", padding: "5px 25px" }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 2, mb: 2 }} maxWidth="xl">
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" flexDirection="column" gap="1rem">
            <TextField
              label="Unique ID"
              name="uniqueId"
              variant="outlined"
              fullWidth
              disabled
              value={formik.values.uniqueId}
              onChange={formik.handleChange}
              error={formik.touched.uniqueId && Boolean(formik.errors.uniqueId)}
              helperText={formik.touched.uniqueId && formik.errors.uniqueId}
            />
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              disabled={user?.reset_email && true}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              label="Name"
              name="name"
              variant="outlined"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              label="Organization"
              name="organization"
              variant="outlined"
              fullWidth
              value={formik.values.organization}
              onChange={formik.handleChange}
              error={formik.touched.organization && Boolean(formik.errors.organization)}
              helperText={formik.touched.organization && formik.errors.organization}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={formik.touched.country && Boolean(formik.errors.country)}
                >
                  <InputLabel>Country</InputLabel>
                  <Select
                    label="Country"
                    name="country"
                    value={formik.values.country}
                    onChange={handleCountryChange}
                    onBlur={formik.handleBlur}
                  >
                    {Object.entries(country_and_states.country).map(([code, name]) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.country && formik.errors.country && (
                    <Typography variant="caption" color="error">
                      {formik.errors.country}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={formik.touched.state && Boolean(formik.errors.state)}
                >
                  <InputLabel>State</InputLabel>
                  <Select
                    label="State"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={isStateDisabled}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.code} value={state.code}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.state && formik.errors.state && (
                    <Typography variant="caption" color="error">
                      {formik.errors.state}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              label="Purpose of Using"
              name="purpose"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={formik.values.purpose}
              onChange={formik.handleChange}
              error={formik.touched.purpose && Boolean(formik.errors.purpose)}
              helperText={formik.touched.purpose && formik.errors.purpose}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                type="submit"
                sx={{
                  padding: "5px 40px",
                  textTransform: "none",
                  backgroundColor: "#166BDA",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#789ecf",
                    boxShadow: "none",
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      </Container>
    </Dialog>
  );
}
