import { Box, Modal } from "@mui/material";
import React from "react";
import { modalStyle } from "./modalStyle";

const ModalBasic = ({ modalOpen, onModalClose, children }) => {
  return (
    <Modal
      open={modalOpen}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...modalStyle, width: 200 }}>{children}</Box>
    </Modal>
  );
};

export default ModalBasic;
