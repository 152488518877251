import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Constants } from "../../config/Constants";
import CreateFormModal from "../Modals/CreateFormModal";
import { GET, POST, PUT } from "../../config/Api";
import FormCard from "./FormCard";
import "./dashboard.scss";
import { ToastNotification } from "../ToastNotification";
import EditFormModal from "../Modals/EditFormModal";
import AddCoeditorModal from "../Modals/AddCoeditorModal";
import { useSelector } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DashboardLayout = () => {
  /* --- STATES --- */
  let nav = useNavigate();
  const toastRef = useRef();

  const [allForms, setAllForms] = useState([]);
  const [inActiveForms, setInActiveForms] = useState([]);
  const [activeSharedForms, setActiveSharedForms] = useState([]);
  const [inActiveSharedForms, setInActiveSharedForms] = useState([]);
  const [copyFormModal, setCopyFormModal] = useState(false);
  const [editFormModal, setEditFormModal] = useState({});
  const [formTitle, setFormTitle] = useState("");
  const [formID, setFormID] = useState("");
  const [mainTabValue, setMainTabValue] = useState(0);
  const [subTabValue, setSubTabValue] = useState(0);
  const [showCoeditorModal, setShowCoeditorModal] = useState(false);

  let loginDetails = useSelector((state) => state.userLoginDetails);

  const navigate = useNavigate();

  /* --- LIFE CYCLES --- */

  useEffect(() => {
    if (loginDetails?.user?.isFormCreator === 1) {
      navigate("/dashboard");
    }
    // else {
    //   navigate("/");
    // }
  }, [loginDetails]);

  useEffect(() => {
    getAllForms();
  }, []);

  useEffect(() => {
    mainTabValue === 1 && getSharedForms();
  }, [mainTabValue]);

  /* --- METHODS --- */

  const createForm = async () => {
    if (formTitle !== "") {
      await POST(`${Constants.form}/form`, { form_title: formTitle })
        .then(async (res) => {
          if (res.data.success) {
            nav(`/create/${res.data.slug}`);
          }
        })
        .catch((err) => toastRef.current.showToastMessage(err.message, "err"));
    } else {
      toastRef.current.showToastMessage("Enter form name", "warning");
    }
  };

  const getAllForms = () => {
    GET(`${Constants.form}/allformsbyowner`)
      .then((res) => {
        if (res.data.success) {
          const activeForms = res.data.forms.filter((el) => el.is_active === true);
          const inActiveFormList = res.data.forms.filter((el) => el.is_active === false);
          setAllForms(activeForms);
          setInActiveForms(inActiveFormList);
        }
      })
      .catch((err) => console.log(err));
  };

  const getSharedForms = () => {
    GET(`${Constants.form}/forms_shared_with_you`)
      .then((res) => {
        if (res.data.success) {
          const activeForms = res.data.forms.filter((el) => el.is_active === true);
          const inActiveFormList = res.data.forms.filter((el) => el.is_active === false);
          setActiveSharedForms(activeForms);
          setInActiveSharedForms(inActiveFormList);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setSubTabValue(0); // Reset sub-tab when switching main tab
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };

  const handleCreateFormClick = (form) => {
    if (form.form_slug) {
      nav(`/create/${form.form_slug}`);
    }
  };

  const handleFetchCsvData = (form_slug) => {
    fetchCsvData(form_slug);
  };

  const generate_csv = (csvData, fileName) => {
    const blob = new Blob(["\uFEFF" + csvData], { type: "text/csv;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const fetchCsvData = async (form_slug) => {
    try {
      const body = { form_slug };
      POST(`${Constants.form_activity}/generate_csv`, body).then((res) => {
        const csvData = res.data;
        if (csvData) {
          generate_csv(csvData, form_slug);
        } else {
          toastRef.current.showToastMessage("No Responses found", "warning");
        }
      });
    } catch (err) {
      toastRef.current.showToastMessage(err.message, "error");
    }
  };

  const copyForm = async () => {
    if (formTitle === "") {
      return toastRef.current.showToastMessage("Enter form name.", "error");
    }
    try {
      await POST(`${Constants.form}/copy/${formID}`, { form_title: formTitle });
      window.location.reload();
    } catch (error) {
      toastRef.current.showToastMessage("Something went wrong.", "error");
    }
  };

  const editForm = async (type, form_id) => {
    if (editFormModal.title === "") {
      return toastRef.current.showToastMessage("Enter form name.", "error");
    }
    try {
      if (type) {
        await PUT(`${Constants.form}/form`, {
          form_id: form_id,
          is_active: type === "inactive" ? false : true,
          type: "status_updated",
        });
        toastRef.current.showToastMessage(
          `Form status changed to ${type === "inactive" ? "inactive" : "active"} successfully.`,
          "success",
        );
      } else {
        await PUT(`${Constants.form}/form`, {
          form_id: editFormModal.formID,
          form_title: editFormModal.title,
        });
        toastRef.current.showToastMessage("Form name updated successfully.", "success");
      }
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      toastRef.current.showToastMessage("Something went wrong.", "error");
    }
  };

  const renderForms = (forms) => {
    return forms.map((form, i) => (
      <FormCard
        key={i}
        form={form}
        setFormID={setFormID}
        setCopyFormModal={setCopyFormModal}
        setEditFormModal={setEditFormModal}
        editForm={editForm}
        onClick={() => handleCreateFormClick(form)}
        onResponseClick={() => handleFetchCsvData(form.form_slug)}
        setShowCoeditorModal={setShowCoeditorModal}
      />
    ));
  };

  if (!loginDetails.isLoggedIn) {
    return "";
  }
  if (!loginDetails?.user?.isFormCreator === 1) {
    navigate("/");
  }
  return (
    <>
      <Grid container className="dashboardWrapper" justifyContent={"center"}>
        <Box
          display="flex"
          sx={{
            flexDirection: { xs: "column", sm: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box>
            <Typography variant="h5" className="createHeader">
              Create Your Form
            </Typography>
          </Box>
          <Box width="50%">
            <TextField
              fullWidth
              value={formTitle}
              onChange={(e) => setFormTitle(e.target.value)}
              placeholder="Enter form title"
              label="Enter form title"
              margin="dense"
              size="small"
            />
          </Box>
          <Box>
            <Button variant="contained" onClick={createForm} fullWidth size="medium">
              Create
            </Button>
          </Box>
        </Box>
        <Grid container className="bottomTabPanel">
          <Box marginBottom={"1rem"} width="100%">
            <Tabs value={mainTabValue} onChange={handleMainTabChange} color="formGreen">
              <Tab className="tabTypography" label="Your Forms" color="formGreen" />
              <Tab className="tabTypography" label="Shared Forms" />
            </Tabs>
          </Box>
          <CustomTabPanel value={mainTabValue} index={0}>
            <Tabs value={subTabValue} onChange={handleSubTabChange} color="formGreen">
              <Tab
                className="tabTypography"
                label="Active"
                style={{
                  color: subTabValue === 0 ? "black" : "grey",
                }}
              />
              <Tab
                className="tabTypography"
                label="Inactive"
                style={{
                  color: subTabValue === 1 ? "black" : "grey",
                }}
              />
            </Tabs>
            <CustomTabPanel value={subTabValue} index={0}>
              <Grid
                item
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                gap={"1rem"}
                justifyContent={"flex-start"}
              >
                {renderForms(allForms)}
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={subTabValue} index={1}>
              <Grid
                item
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                gap={"1rem"}
                justifyContent={"flex-start"}
              >
                {renderForms(inActiveForms)}
              </Grid>
            </CustomTabPanel>
          </CustomTabPanel>
          <CustomTabPanel value={mainTabValue} index={1}>
            <Tabs value={subTabValue} onChange={handleSubTabChange} color="formGreen">
              <Tab
                className="tabTypography"
                label="Active"
                style={{
                  color: subTabValue === 0 ? "black" : "grey",
                }}
              />
              <Tab
                className="tabTypography"
                label="Inactive"
                style={{
                  color: subTabValue === 1 ? "black" : "grey",
                }}
              />
            </Tabs>
            <CustomTabPanel value={subTabValue} index={0}>
              <Grid
                item
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                gap={"1rem"}
                justifyContent={"flex-start"}
              >
                {renderForms(activeSharedForms)}
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={subTabValue} index={1}>
              <Grid
                item
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                gap={"1rem"}
                justifyContent={"flex-start"}
              >
                {renderForms(inActiveSharedForms)}
              </Grid>
            </CustomTabPanel>
          </CustomTabPanel>
        </Grid>
      </Grid>
      <ToastNotification ref={toastRef} duration={2000} />
      <CreateFormModal
        modalOpen={copyFormModal}
        onModalClose={() => {
          setCopyFormModal(false);
          setFormID("");
        }}
        modalTitle={"Create Form Copy"}
        value={formTitle}
        onChange={(e) => setFormTitle(e.target.value)}
        onSave={copyForm}
      />
      <EditFormModal
        modalOpen={editFormModal.modal}
        onModalClose={() => {
          setEditFormModal((prev) => ({ ...prev, modal: false, formID: "", title: "" }));
        }}
        modalTitle={"Edit Form Name"}
        value={editFormModal?.title}
        onChange={(e) => setEditFormModal((prev) => ({ ...prev, title: e.target.value }))}
        onSave={editForm}
      />
      <AddCoeditorModal
        showCoeditorModal={showCoeditorModal}
        setShowCoeditorModal={setShowCoeditorModal}
      />
    </>
  );
};

export default DashboardLayout;
