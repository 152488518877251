import React, { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Box, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import PendingUsers from "./pendingUsers";
import ApprovedUsers from "./approvedUsers";
import "./admin.scss";
import { GET, POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { ToastNotification } from "../ToastNotification";
import useDebounce from "../Hooks/useDebounce";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AdminLayout = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [approvedCurrentPage, setApprovedCurrentPage] = useState(1);
  const [approvedTotalPages, setApprovedTotalPages] = useState(1);
  const [pendingCurrentPage, setPendingCurrentPage] = useState(1);
  const [pendingTotalPages, setPendingTotalPages] = useState(1);
  const [approvedUserLoading, setApprovedUserLoading] = useState(true);
  const [pendingUserLoading, setPendingUserLoading] = useState(true);
  const { search, setSearch, debouncedSearch, handleSearchChange } = useDebounce("", 300);

  const navigate = useNavigate();
  const toastRef = useRef();
  const loginDetails = useSelector((state) => state.userLoginDetails);

  // Check if the user is logged in and is an admin
  useEffect(() => {
    if (loginDetails?.user?.type !== "admin") {
      navigate("/");
    }
  }, [loginDetails, navigate]);

  // Fetch approved users based on the current tab and search term
  useEffect(() => {
    if (selectedTab === 0) {
      // Call getApprovedUsers if the search term is empty
      if (!debouncedSearch || debouncedSearch.length <= 3) {
        getApprovedUsers();
      } else {
        searchApprovedUser();
      }
    }
  }, [selectedTab, approvedCurrentPage, debouncedSearch]);

  // Fetch pending users based on the current tab and search term
  useEffect(() => {
    if (selectedTab === 1) {
      if (!debouncedSearch || debouncedSearch.length <= 3) {
        getPendingUsers();
      } else {
        searchPendingUser();
      }
    }
  }, [selectedTab, pendingCurrentPage, debouncedSearch]);

  const getApprovedUsers = async () => {
    try {
      const response = await GET(
        `${Constants.getApprovedUsers}?page=${approvedCurrentPage}&limit=5`,
      );
      setApprovedUsers(response.data.users);
      setApprovedTotalPages(response.data.totalPages);
      setApprovedUserLoading(false);
    } catch (error) {
      setApprovedUserLoading(false);
    }
  };

  const searchApprovedUser = async () => {
    try {
      const response = await GET(
        `${
          Constants.searchApprovedAndPendingUser
        }?search=${debouncedSearch.toLowerCase()}&isFormCreator=1`,
      );
      setApprovedUsers(response.data.users);
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingUsers = async () => {
    try {
      const response = await GET(`${Constants.getPendingUsers}?page=${pendingCurrentPage}&limit=5`);
      setPendingUsers(response.data.users);
      setPendingTotalPages(response.data.totalPages);
      setPendingUserLoading(false);
    } catch (error) {
      console.log(error);
      setPendingUserLoading(false);
    }
  };

  const searchPendingUser = async () => {
    try {
      const response = await GET(
        `${
          Constants.searchApprovedAndPendingUser
        }?search=${debouncedSearch.toLowerCase()}&isFormCreator=3`,
      );
      setPendingUsers(response.data.users);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApproveRequest = async (id) => {
    try {
      const payload = { user_id: id };
      const response = await POST(`${Constants.approveFormCreatorRequest}`, payload);
      setPendingUsers((prevUsers) => {
        const updatedUsers = prevUsers.filter((user) => user._id !== id);
        if (updatedUsers.length === 0 && pendingCurrentPage > 1) {
          setPendingCurrentPage(pendingCurrentPage - 1);
        }
        return updatedUsers;
      });
      toastRef.current.showToastMessage(response.data.message, "success");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDenyRequest = async (id) => {
    try {
      const payload = { user_id: id };
      const response = await POST(`${Constants.denyFormCreatorRequest}`, payload);
      setPendingUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
      toastRef.current.showToastMessage(response.data.message, "success");
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSearch("");
    setPendingCurrentPage(1);
    setApprovedCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    if (selectedTab === 0) {
      setApprovedCurrentPage(page);
    } else {
      setPendingCurrentPage(page);
    }
  };

  if (Object.keys(loginDetails).length === 0) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", typography: "body1" }} className="adminWrapper">
      <Grid container className="bottomTabPanel">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="admin tabs"
          color="formGreen"
        >
          <Tab label="Approved Requests" color="formGreen" className="tabTypography" />
          <Tab label="Pending Requests" className="tabTypography" />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <Grid
            item
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            gap={"1rem"}
            justifyContent={"flex-start"}
          >
            <ApprovedUsers
              approvedUsers={approvedUsers}
              currentPage={approvedCurrentPage}
              totalPages={approvedTotalPages}
              handlePageChange={handlePageChange}
              search={search}
              handleSearchChange={handleSearchChange}
              loading={approvedUserLoading}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Grid
            item
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            gap={"1rem"}
            justifyContent={"flex-start"}
          >
            <PendingUsers
              pendingUsers={pendingUsers}
              handleDenyRequest={handleDenyRequest}
              handleApproveRequest={handleApproveRequest}
              search={search}
              handleSearchChange={handleSearchChange}
              loading={pendingUserLoading}
              currentPage={pendingCurrentPage}
              totalPages={pendingTotalPages}
              handlePageChange={handlePageChange}
            />
          </Grid>
        </TabPanel>
      </Grid>
      <ToastNotification ref={toastRef} duration={2000} />
    </Box>
  );
};

export default AdminLayout;
