import React from "react";
import { Box, Button, Tooltip } from "@mui/material";
import "./FormComponent.scss";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

const CreateSectionButton = ({ onClick }) => {
  return (
    <Box className="createSectionWrapper" sx={{ marginLeft: { xs: "3rem", lg: "6.5rem" } }}>
      <Tooltip title="Add Section" placement="bottom-end">
        <Button
          className="createSectionButton"
          onClick={onClick}
          color="primary"
          sx={{ backgroundColor: "white" }}
        >
          <ControlPointOutlinedIcon color="blue" sx={{ fontSize: "1.5rem" }} />
          Add Section
        </Button>
      </Tooltip>
    </Box>
  );
};

export default CreateSectionButton;
