import React from "react";
import FormTitle from "../FormComponents/FormTitle";
import ImageContainer from "../FormComponents/ImageContainer";
import FormQuestions from "../FormComponents/FormQuestions";
import { IMAGE_TYPE, QUESTION_TYPE, TITLE_TYPE } from "../../../config/Constants";

const FormList = ({ componentData, toastRef, onDelete, onCopy, isDragging, getFormBySlug }) => {
  switch (componentData.type) {
    case TITLE_TYPE:
      return <FormTitle componentData={componentData} onDelete={onDelete} toastRef={toastRef} getFormBySlug={getFormBySlug} />;
    case QUESTION_TYPE:
      return (
        <FormQuestions
          componentData={componentData}
          onDelete={onDelete}
          toastRef={toastRef}
          onCopy={onCopy}
          isDragging={isDragging}
          getFormBySlug={getFormBySlug}
        />
      );
    case IMAGE_TYPE:
      return (
        <ImageContainer onDelete={onDelete} componentData={componentData} toastRef={toastRef} getFormBySlug={getFormBySlug} />
      );
    default:
      return null;
  }
};

export default FormList;
