import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import "../Form.scss";
import ButtonMain from "../../Buttons/ButtonMain";
import { cloudfrontUrl } from "../../../config/BaseUrl";

const FormBanner = ({ banner, handeFormBannerUpload, toastRef, formTitle }) => {
  /* --- HOOKS --- */
  const fileInputRef = useRef(null);
  /* --- STATES --- */
  const [anchorEl, setAnchorEl] = useState(null);
  const [mediaLocal, setMediaLocal] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isEdit, setIsEdit] = useState(true);
  /* --- LIFE CYCLES --- */
  useEffect(() => {
    if (banner) {
      setIsEdit(false);
    }
  }, [banner]);

  /* --- METHODS --- */
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleRemoveImage = () => {
  //   // setIsEdit(false);
  //   setSelectedMedia(null);
  //   setMediaLocal(null);
  // };

  const handleImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the uploaded file is an image (image/* mime type)
      if (file.type.startsWith("image/")) {
        // Check if the file size is within the specified limit (2MB)
        if (file.size <= 2 * 1024 * 1024) {
          const imageURL = URL.createObjectURL(file);
          handleClose(event);
          setIsEdit(true);
          setSelectedMedia(file);
          setMediaLocal(imageURL);
        } else {
          // Handle file size validation error
          toastRef.current.showToastMessage(
            "Please select an image file smaller than 2MB.",
            "error",
          );
        }
      } else {
        // Handle file type validation error
        toastRef.current.showToastMessage("Please select a valid image file.", "error");
      }
    }
  };
  const handleFileInputBlur = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length === 0) {
      // Handle your logic here
    }
  };

  /* --- COMPONENT RETURN --- */
  return (
    <Grid item position={"relative"} className="bannerWrapper">
      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        onBlur={handleFileInputBlur}
        ref={fileInputRef}
      />
      <Box>
        {isEdit ? (
          mediaLocal ? (
            <img
              className="sectionImg"
              src={mediaLocal}
              alt={"section_banner"}
              style={{ width: "100%" }}
            />
          ) : (
            <Grid item className="sectionUploadMessage">
              <ButtonMain onClick={handleImageUpload}>Upload Banner Image</ButtonMain>
            </Grid>
          )
        ) : (
          <img
            className="sectionImg"
            src={`${cloudfrontUrl}/${banner}`}
            alt={banner}
            style={{ width: "100%" }}
          />
        )}
        {formTitle && <Box className="overlay-text">{formTitle}</Box>}
      </Box>
      <Grid item position={"absolute"} className="optionsWrapper">
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleImageUpload}>Upload Image</MenuItem>
          {/* <MenuItem onClick={handleRemoveImage}>Remove</MenuItem> */}
        </Menu>
      </Grid>
      {isEdit && mediaLocal && (
        <Grid item position={"absolute"} left={"15px"} bottom={"20px"}>
          <ButtonMain onClick={() => handeFormBannerUpload(selectedMedia)}>Save</ButtonMain>
        </Grid>
      )}
    </Grid>
  );
};

export default FormBanner;
