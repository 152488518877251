import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { styled } from "@mui/material/styles";
import ButtonMain from "../Buttons/ButtonMain";
import { useParams } from "react-router-dom";
import { sortComponentByOrder } from "../../utils";
import { GETwithoutAuth } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { useState } from "react";
import { Alert } from "@mui/material";

// Create a styled component for the backdrop
const StyledBackdrop = styled("div")({
    backdropFilter: "blur(10px)", // Adjust the blur value as needed
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: add some background color
});

export default function PreviewEmailModal({ open, setOpen, uniqueId, setUniqueId, email, setEmail, setFormConfig, formConfig }) {
    const [emailError, setEmailError] = useState("");
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);
    const { slug } = useParams();

    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (e, setter) => {
        setter(e.target.value);
    };


    const getUserActivityByEmail = async () => {
        try {
            const res = await GETwithoutAuth(`${Constants.form_activity}/get_activity_by_email/${email}`);
            return res.data.success;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    //get random section if set by moderator
    const getFormBySlug = async () => {
        GETwithoutAuth(`${Constants.form_activity}/get_preview_form/${slug}?email=${email}`)
            .then((res) => {
                if (res.data.success) {
                    setFormConfig(sortComponentByOrder(res.data.form[0]));
                }
            })
            .catch((err) => console.log(err));
    };

    const getOlderFormBySlug = async () => {
        GETwithoutAuth(`${Constants.form_activity}/get_older_preview_form/${slug}?email=${email}`)
            .then((res) => {
                if (res.data.success) {
                    setFormConfig(sortComponentByOrder(res.data.form));
                }
            })
            .catch((err) => console.log(err));
    };
    const handleSubmit = async () => {
        // Validate email
        if (email.trim() === "") {
            setEmailError("Unique ID/email cannot be empty.");
            return;
        }
        if (!email.trim().includes("@")) {
            setEmailError("Invalid email address");
            return;
        }

        try {
            if (formConfig?.require_login && !formConfig?.enable_multiple_submission) {
                // Check if the user has already submitted the form using email
                const isActivityExist = await getUserActivityByEmail();

                // If true - bring older section (getOlderFormBySlug) else random section
                if (isActivityExist) {
                    setAlreadySubmitted(true);
                    // await getOlderFormBySlug();
                } else {
                    // Get random section
                    await getFormBySlug();
                    setOpen(false);
                }
            } else if (formConfig?.require_login && formConfig?.identity_required) {
                // Get last attempted section by email
                await getOlderFormBySlug();
                setOpen(false);
            } else {
                // Get random section
                await getFormBySlug();
                setOpen(false);

            }
        } catch (error) {
            console.error("Error handling form submission", error);
            setAlreadySubmitted(false);
        }
        // finally {
        //     setOpen(false);
        // }
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
                BackdropProps={{
                    component: StyledBackdrop,
                }}
            >

                <DialogTitle>Add Your Email/Framerspace Unique Id</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Note :Enter email/framerspace unique id for maintain the same sequence of sections in future.
                    </DialogContentText>

                    {/* <TextField
                        required
                        margin="dense"
                        label="Framerspace Unique Id"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={uniqueId}
                        onChange={(e) => handleChange(e, setUniqueId)}
                    
                    /> */}
                    <TextField
                        autoFocus
                        required
                        margin="dense"

                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={email}
                        onChange={(e) => handleChange(e, setEmail)}
                        error={!!emailError}
                        helperText={emailError}
                    />
                    {alreadySubmitted ? <Alert severity="info" sx={{ alignItems: "center" }}>You have already submitted the form.</Alert> : ""}
                </DialogContent>
                <DialogActions>
                    <ButtonMain onClick={handleSubmit}>Next</ButtonMain>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
