import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import ModalBasic from "./ModalBasic";
import ButtonMain from "../Buttons/ButtonMain";

const CreateFormModal = ({ modalOpen, onModalClose, value, onChange, onSave, modalTitle }) => {
  return (
    <ModalBasic modalOpen={modalOpen} onModalClose={onModalClose}>
      <Grid>
        <Typography
          sx={{
            fontFamily: "'IBM Plex Serif', serif",
            fontSize: "32px",
            fontWeight: "600",
            mb: "10px",
          }}
        >
          {modalTitle}
        </Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        display={"flex"}
        flexDirection={"column"}
        // alignItems={"center"}
        // justifyContent={"center"}
      >
        <Grid item>
          <TextField required label="Form Name" fullWidth value={value} onChange={onChange} />
        </Grid>
        <Grid item width={"100%"}>
          <ButtonMain style={{ width: "100%" }} onClick={onSave}>
            Create
          </ButtonMain>
        </Grid>
      </Grid>
    </ModalBasic>
  );
};

export default CreateFormModal;
