import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { formConfigReducer, loginReducer } from "./reducers";

// Combine Reducers
const rootReducer = combineReducers({
  form_config: formConfigReducer,
  userLoginDetails: loginReducer,
});

// Redux Persist Configuration
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["form_config"], // Exclude persisting this reducer
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure Store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

// Initialize Redux Persist
export const persistor = persistStore(store);
