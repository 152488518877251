import React from "react";
import { Grid, Paper } from "@mui/material";
import "../formPreview.scss";

const SectionTitle = ({ title, description }) => {
  return (
    <Paper elevation={2} className="sectionTitlePaper">
      <div className="sectionTitleHighlight" />
      <Grid container>
        <Grid item xs={12}>
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SectionTitle;
