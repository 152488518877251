import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, ThemeProvider } from "@mui/material";
import { userLogoutAction } from "../../redux/action";

import "./header.scss";
import LogoComponent from "./LogoComponent";
import { THEME } from "../Themes/theme";
import CreateHeaderOptions from "./CreateHeaderOptions";
import { Constants } from "../../config/Constants";
import { GET } from "../../config/Api";

const CreateFormHeader = ({ children, onPreview, onLinkCopy, onCodeCopy, onJoiningCodeCopy }) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let loginDetails = useSelector((state) => state.userLoginDetails);

  useEffect(() => {
    if (Object.keys(loginDetails).length === 0) {
      navigate("/");
    }
  }, [loginDetails]);

  const handleLogout = async () => {
    await GET(`${Constants.userLogout}?userID=${loginDetails?.user?.id}`);
    dispatch(userLogoutAction());
    navigate("/");
  };

  return (
    <ThemeProvider theme={THEME}>
      <Grid
        container
        width={"100%"}
        flexDirection={"column"}
        sx={{ background: "var(--formBackgroundBlue)" }} // theme
        className="createHeaderWrapper"
      >
        <Grid item className="headerMain">
          <LogoComponent />

          <Grid item display={"flex"} gap={"1rem"} alignItems={"center"}>
            <CreateHeaderOptions
              onPreview={onPreview}
              onLinkCopy={() => {
                onLinkCopy();
              }}
              onJoiningCodeCopy={onJoiningCodeCopy}
              onCodeCopy={onCodeCopy}
              handleLogout={handleLogout}
            />
          </Grid>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default CreateFormHeader;
