import React, { useEffect } from "react";
import { Container, Grid, Typography, Button, Box } from "@mui/material";
import surveyImage from "../../Assets/images/survey.jpg";
import RequestForCreatorModal from "../Modals/RequestForCreatorModal";
import { useState } from "react";
import { getCookie } from "../../utils";
import { userLoginAction } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const HomeLayout = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, isLoggedIn, user } = useSelector((state) => state.userLoginDetails);

  /* --- LIFE CYCLES --- */
  useEffect(() => {
    let cookieName =
      process.env.REACT_APP_SERVER_TYPE === "production" ? "fsUserData_PROD" : "fsUserData";
    let userData = getCookie(cookieName);
    if (userData && !success) {
      dispatch(userLoginAction(JSON.parse(userData)));
    } else if (isLoggedIn) {
      if (user?.isFormCreator === 1) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    }
  }, [isLoggedIn]);

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container spacing={3} alignItems="center" padding={"0"}>
        <Grid item xs={12} md={6} sx={{ textAlign: { xs: "center", md: "right" } }}>
          <img src={surveyImage} alt="FramerSpace" style={{ maxWidth: "100%", height: "auto" }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
            <Typography variant="h4" fontWeight="bold" sx={{ mb: 2 }}>
              Empowering Learners
            </Typography>
            <Typography variant="body2" sx={{ mb: 4 }}>
              FramerSpace is a co-creation platform that provides building blocks to support the
              creation of online courses and connects learners to peers and creators through
              Artificial Intelligence.
            </Typography>
            <Button
              variant="contained"
              onClick={() =>
                isLoggedIn
                  ? setOpen(true)
                  : window.location.replace(
                      `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location}`,
                    )
              }
              sx={{
                borderRadius: "30px",
                padding: "13px 30px",
                textTransform: "none",
                backgroundColor: "black",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "black", // Keeps the background color the same on hover
                  boxShadow: "none", // Removes the box shadow on hover
                },
              }}
            >
              {isLoggedIn ? "Request for form creator license" : "Login/SignUp to continue"}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <RequestForCreatorModal open={open} setOpen={setOpen} />
    </Container>
  );
};

export default HomeLayout;
