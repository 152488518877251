import React from "react";
import { Box } from "@mui/system";
import { Paper, Tooltip } from "@mui/material";

import TextFieldsIcon from "@mui/icons-material/TextFields";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

const FormToolbar = ({ handleAddQuestion, handleAddTitle, handleAddImageVideo }) => {
  return (
    <>
      <Paper elevation={3}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", padding: "0.5rem 1rem" }}>
          <Tooltip title="Add Question" placement="top-start">
            <AddBoxOutlinedIcon sx={{ cursor: "pointer" }} onClick={handleAddQuestion} />
          </Tooltip>
          <Tooltip title="Add text" placement="top-start">
            <TextFieldsIcon sx={{ cursor: "pointer" }} onClick={handleAddTitle} />
          </Tooltip>
          <Tooltip title="Add Image" placement="top-start">
            <ImageOutlinedIcon sx={{ cursor: "pointer" }} onClick={handleAddImageVideo} />
          </Tooltip>
          {/* <Tooltip title="Add Video" placement="top-start">
            <SlideshowIcon sx={{ cursor: "pointer" }} />
          </Tooltip> */}
          {/* <Tooltip title="Add Section" placement="top-start">
            <CalendarViewDayIcon sx={{ cursor: "pointer" }} />
          </Tooltip> */}
        </Box>
      </Paper>
    </>
  );
};

export default FormToolbar;
