import React, { memo, useEffect } from "react";
import { Grid } from "@mui/material";
import ComponentContainer from "../ComponentContainer";
import FormFields from "./FormFields";

import FormAction from "./FormAction";
import { POST, PUT } from "../../../config/Api";
import {
  Constants,
  QUESTION_INPUT,
  QUESTION_LINEAR_SCALE,
  QUESTION_TYPE,
  QuestionTypes,
} from "../../../config/Constants";
import QuestionTitle from "./QuestionTitle";

const FormQuestions = ({
  onDelete,
  componentData,
  toastRef,
  onCopy,
  isDragging,
  getFormBySlug,
}) => {
  /* --- HOOKS --- */
  /* --- STATES --- */
  const [fieldValue, setFieldValue] = React.useState(QUESTION_INPUT);
  const [questionData, setQuestionData] = React.useState({
    question_title: "",
    question_type: "input",
    options: [],
    linearOptions: [],
    section_id: "",
    is_required: false,
    linear_scale_label: {},
    label_start: "",
    label_end: "",
  });
  const [isEdit, setIsEdit] = React.useState(false);

  /* --- LIFE CYCLES --- */

  useEffect(() => {
    if (componentData) {
      let upOp = componentData.question?.options.map((item) => item.value);
      let qType = QuestionTypes.find((type) => type.key === componentData.question.qType);
      setFieldValue(qType.key);
      const commonProperties = {
        form_id: componentData.form_id,
        _id: componentData._id,
        section_id: componentData.section_id,
        question_title: componentData.question.qTitle,
        question_type: componentData.question.qType,
        order: componentData.order,
        label_start: componentData.question?.linear_scale_label?.label_start,
        label_end: componentData.question?.linear_scale_label?.label_end,
        is_required: componentData.question.is_required,
      };

      const updatedData =
        componentData.question.qType === QUESTION_LINEAR_SCALE
          ? { ...commonProperties, linearOptions: upOp }
          : { ...commonProperties, options: upOp };

      setQuestionData({
        ...questionData,
        ...updatedData,
      });
      if (componentData?.newComponent) {
        setIsEdit(false);
      } else {
        setIsEdit(true);
      }
    }
  }, [componentData]);

  // --- METHODS ---

  function questionValidation(questionData) {
    if (questionData.question_title !== "") {
      return true;
    } else return false;
  }

  const handleFormQuestions = async () => {
    setIsEdit(true);
    if (questionValidation(questionData)) {
      let body = {
        form_id: questionData.form_id,
        section_id: questionData.section_id,
        type: QUESTION_TYPE,
        media_title: "",
        order: questionData.order,
        question: {
          title: questionData.question_title,
          image: "",
          q_type: questionData.question_type,
          is_calendar: false,
          is_time: false,
          // options: questionData.options,
          is_required: questionData.is_required,
          linear_labels: {
            label_start: questionData.label_start,
            label_end: questionData.label_end,
          },
        },
      };

      if (questionData.question_type === QUESTION_LINEAR_SCALE) {
        body = {
          ...body,
          question: {
            ...body.question,
            options: questionData.linearOptions,
          },
        };
      } else {
        body = {
          ...body,
          question: {
            ...body.question,
            options: questionData.options,
          },
        };
      }

      if (questionData._id) {
        body.component_id = questionData._id;
        PUT(`${Constants.form}/component`, body)
          .then((res) => {
            getFormBySlug();
            toastRef.current.showToastMessage(res.data.message, "success");
          })
          .catch((err) => toastRef.current.showToastMessage(err.message, "err"));
      } else {
        POST(`${Constants.form}/component`, body)
          .then((res) => {
            toastRef.current.showToastMessage(res.data.message, "success");
            getFormBySlug();
            setQuestionData({
              ...questionData,
              _id: res.data.config_id,
            });
          })
          .catch((err) => {
            toastRef.current.showToastMessage(err.message, "error");
          });
      }
    } else {
      toastRef.current.showToastMessage("Question Title cannot be empty.", "warning");
    }
  };

  const handleQuestionTypeSelect = (e) => {
    setIsEdit(false);
    setFieldValue(e.target.value);
    let qType = QuestionTypes.find((type) => type.key === e.target.value);
    if (qType.key === QUESTION_LINEAR_SCALE) {
      setQuestionData({
        ...questionData,
        question_type: qType.key,
        linearOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      });
    } else {
      setQuestionData({ ...questionData, question_type: qType.key });
    }
  };

  const handleTitleChange = (e) => {
    setIsEdit(false);
    setQuestionData({ ...questionData, question_title: e.target.value });
  };

  const handleEdit = () => {
    setIsEdit(false);
  };

  return (
    <ComponentContainer isDragging={isDragging} isEdit={isEdit}>
      <Grid
        container
        display={"flex"}
        justifyContent={"space-between"}
        rowSpacing={2}
        columnSpacing={2}
      >
        <QuestionTitle
          questionData={questionData}
          fieldValue={fieldValue}
          handleQuestionTypeSelect={handleQuestionTypeSelect}
          handleTitleChange={handleTitleChange}
        />
        <Grid item lg={12}>
          <FormFields
            fieldValue={fieldValue}
            options={questionData.options}
            linearOptions={questionData.linearOptions}
            questionData={questionData}
            setQuestionData={setQuestionData}
            handleEdit={handleEdit}
          />
        </Grid>

        <Grid
          item
          display={"flex"}
          alignItems={"center"}
          justifyContent={!isEdit ? "space-between" : "flex-end"}
          width={"100%"}
        >
          <FormAction
            saveVisible={true}
            saveDisabled={isEdit}
            onSave={handleFormQuestions}
            checked={questionData.is_required}
            handleRequired={() => {
              if (isEdit) {
                setIsEdit(false);
              }
              setQuestionData({
                ...questionData,
                is_required: !questionData.is_required,
              });
            }}
            onCopy={() => onCopy(componentData)}
            onDelete={() => {
              onDelete(componentData.order, questionData._id);
            }}
            isRequiredVisible={true}
            hideDuplicate={true}
          />
        </Grid>
      </Grid>
    </ComponentContainer>
  );
};

export default memo(FormQuestions);
