import { useState, useEffect } from "react";

const useDebounce = (initialValue = "", delay = 300) => {
  const [search, setSearch] = useState(initialValue);
  const [debouncedSearch, setDebouncedSearch] = useState(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [search, delay]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return {
    search,
    setSearch,
    debouncedSearch,
    handleSearchChange,
  };
};

export default useDebounce;
