import { deleteCookie } from "../utils";
import {
  ADD_NEW_SECTION,
  FORM_CONFIG_FAIL,
  FORM_CONFIG_SAVE,
  FORM_CONFIG_UPDATE,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_SECTION_SECTION,
} from "./actionTypes";

export const saveFormId = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FORM_CONFIG_SAVE,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FORM_CONFIG_FAIL,
      payload: err.message,
    });
  }
};

export const updateFormConfigAction = (updatedConfig) => {
  return {
    type: FORM_CONFIG_UPDATE,
    payload: updatedConfig,
  };
};

export const userLoginAction = (body) => async (dispatch) => {
  try {
    // dispatch({
    //   type: LOGIN_REQUEST,
    // });
    // let loginData = await POST(Constants.userLogin, body);
    // console.log("LOGINNN", loginData);
    // if (!loginData.data.success) {
    //   dispatch({
    //     type: LOGIN_FAIL,
    //     payload: loginData.data.message,
    //   });
    // } else {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: body,
    });
    // }
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: err.message,
    });
  }
};

export const userLogoutAction = (body) => async (dispatch) => {
  // try {
  let cookieName =
    process.env.REACT_APP_SERVER_TYPE === "production" ? "fsUserData_PROD" : "fsUserData";
  deleteCookie(cookieName);
  dispatch({
    type: LOGOUT,
  });
  // } catch (err) {}
};

export const AddNewSectionAction = (body) => async (dispatch) => {
  dispatch({
    type: ADD_NEW_SECTION,
    payload: body,
  });
};

export const updateSectionAction = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_SECTION_SECTION,
    payload: body,
  });
};
