import React from "react";
import DashboardHeader from "../Components/Header/DashboardHeader";
import HomeLayout from "../Components/HomeLayout";

const Home = () => {
  return (
    <DashboardHeader>
      <HomeLayout />
    </DashboardHeader>
  );
};

export default Home;
