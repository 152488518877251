export const removeHTMLElement = (str) => {
  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = str;

  // Get the text content from the div
  return tempDiv.textContent;
};

export function sortComponentByOrder(formConfig) {
  formConfig.section.forEach((comp) => {
    comp.component.sort((a, b) => a.order - b.order);
  });
  return formConfig;
}

export function covertISOToIndian(isoDateString) {
  const date = new Date(isoDateString);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
    // timeZoneName: "short",
    timeZone: "Asia/Kolkata",
  };
  const indianFormattedDate = date.toLocaleDateString("en-US", options);

  return indianFormattedDate;
}

export const compareObjects = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export const convertQuestionData = (allSections, sectionIndex) => {
  return allSections[sectionIndex]?.component
    ?.map((comp) => {
      return {
        [comp?._id]: {
          response: comp?.question?.qType === "checkbox" ? [] : "",
          type: comp?.question?.qType,
        },
      };
    })
    ?.reduce((acc, curr, i, arr) => {
      return { ...acc, ...curr };
    }, {});
};

export const getCookie = (name) => {
  let cookieArr = document.cookie.split(";");
  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=");

    // Remove whitespace at the beginning of the cookie name and compare it to the given name
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

export const deleteCookie1 = (name) => {
  console.log(name);
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  // document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const deleteCookie = (name, domain = ".framerspace.com") => {
  document.cookie =
    name + "=; Path=/; Domain=" + domain + "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
