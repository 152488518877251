export const FORM_CONFIG_SAVE = "FORM_CONFIG_SAVE";
export const FORM_CONFIG_UPDATE = "FORM_CONFIG_UPDATE";
export const FORM_CONFIG_FAIL = "FORM_CONFIG_FAIL";
export const FORM_CONFIG_RESET = "FORM_CONFIG_RESET";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const ADD_NEW_SECTION = "ADD_NEW_SECTION";
export const UPDATE_SECTION_SECTION = "UPDATE_SECTION_SECTION";

export const ADD_NEW_COMPONENT = "ADD_NEW_COMPONENT";
export const COPY_COMPONENT = "COPY_COMPONENT";
export const REMOVE_COMPONENT = "REMOVE_COMPONENT";
