import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Box,
  ListSubheader,
  Typography,
  Backdrop,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { debounce } from "lodash";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./SearchBar.scss";
import { useSelector } from "react-redux";
import { GET, POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { ToastNotification } from "../ToastNotification";
const SearchBar = ({ setMembersList, showCoeditorModal }) => {
  let UserData = useSelector((state) => state.userLoginDetails);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const toastRef = useRef();

  const handleSearchInputChange = debounce(async (value) => {
    setLoading(true);
    if (value.length >= 3) {
      //search user
      GET(`${Constants.userSearch}${value.replace(/\s/g, "")}`)
        .then((res) => {
          setLoading(false);
          setSuggestions(res.data.data);
        })
        .catch((err) => {
          setLoading(false);
          toastRef.current.showToastMessage(err?.response?.data?.message, "error");
        });
    } else {
      setLoading(false);
      setSuggestions([]);
    }
  }, 500);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event?.target?.value !== "") {
      setSuggestions([]);
      event.target.value = "";
    }
  };

  const handleAddData = (item) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("form_id", showCoeditorModal.form_id);
    formData.append("user_id", item._id);
    formData.append("unique_id", item.email);
    formData.append("added_by", UserData.user.id);

    POST(`${Constants.form}/add_member`, formData)
      .then((res) => {
        res?.data?.success &&
          setMembersList((prevList) => [
            ...prevList,
            {
              _id: item._id,
              permit_val: "moderator", // Assuming this is static or determined logic
              user: {
                _id: item._id,
                name: item.name,
                initials: item.initials,
                username: item.username,
                email: item.email,
              },
            },
          ]);
        toastRef.current.showToastMessage(res?.data?.message, "success");
        setSuggestions([]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toastRef.current.showToastMessage("Something went wrong.Could not add member", "error");
        console.log(err);
      });
  };

  // Render Search Suggestions
  const renderSearchSuggestions = (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        position: "absolute",
        top: 55,
        boxShadow:
          "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;",
        zIndex: 10,
        overflow: "auto",
        maxHeight: 440,
        "& ul": { padding: 0 },
      }}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Typography variant="body1" component={"strong"}>
            Your search results:
          </Typography>
        </ListSubheader>
      }
    >
      {suggestions &&
        suggestions?.length !== 0 &&
        suggestions.map((item, i) => (
          <ListItem
            key={item.email}
            className="margin-top-2"
            secondaryAction={
              <IconButton
                onClick={() => {
                  handleAddData(item);
                }}
                edge="end"
                color="default"
                aria-label="comments"
              >
                {/* Add */}
                <AddOutlinedIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton dense>
              <ListItemIcon>
                <Box
                  height={40}
                  width={40}
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  bgcolor={"#f5f5f5"}
                  borderRadius={"50%"}
                >{`${item?.initials}`}</Box>
              </ListItemIcon>
              <ListItemText primary={`${item?.email}`} />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );

  return (
    <>
      <div className="search_container">
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: 999 }}
            open={loading}
            onClick={() => setLoading(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <TextField
          id="filled-adornment-password"
          size="large"
          fullWidth
          placeholder="Search Users..."
          onChange={(e) => {
            handleSearchInputChange(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          startAdornment={
            <InputAdornment position="end">
              <IconButton disabled aria-label="Search Dropdown" edge="start">
                <SearchOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        {suggestions && suggestions?.length > 0 && renderSearchSuggestions}
        <ToastNotification ref={toastRef} duration={3000} />
      </div>
    </>
  );
};

export default SearchBar;
