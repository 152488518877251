import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FormList from "./FormList/FormList";
import { POST } from "../../config/Api";
import { Constants } from "../../config/Constants";

function DraggableContent({
  sectionIndex,
  allSections,
  toastRef,
  handleRemoveComponent,
  handleCopyComponent,
  formId,
  getFormBySlug
}) {
  const handleDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return; // Dropped outside the list

    const newComponents = Array.from(allSections[sectionIndex].component);
    const [removed] = newComponents.splice(source.index, 1);
    newComponents.splice(destination.index, 0, removed);

    const updatedAllSections = [...allSections];
    updatedAllSections[sectionIndex].component = newComponents;
    const updatedOrder = newComponents.map((component, index) => ({
      id: component._id,
      order: index + 1,
    }));
    // Update order if necessary
    await updateOrder(updatedOrder);
  };

  const updateOrder = async (updatedOrder) => {
    try {
      await POST(`${Constants.update_order}/${formId}`, updatedOrder);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {allSections[sectionIndex]?.component?.length > 0 && (
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {allSections[sectionIndex]?.component?.map((component, index) => (
                <Draggable key={component._id} draggableId={component?._id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <FormList
                        componentData={component}
                        toastRef={toastRef}
                        onDelete={handleRemoveComponent}
                        onCopy={handleCopyComponent}
                        isDragging={snapshot.isDragging}
                        getFormBySlug={getFormBySlug}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  );
}

export default DraggableContent;
