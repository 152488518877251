import React, { useState } from "react";
import { Card, CardContent, Button, Typography, Box } from "@mui/material";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import UserRequestDetailsModal from "../Modals/UserRequestDetailsModal";
import VerifiedIcon from "@mui/icons-material/Verified";

const FormCreatorRequestCard = ({
  user,
  buttonText,
  handleDenyRequest,
  handleApproveRequest,
  color,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Card
        sx={{
          width: "100%",
          boxShadow: 3,
          mb: 1,
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "0.5rem",
            textAlign: "left",
            padding: "1rem",
          }}
          style={{ padding: "10px" }}
        >
          <Box>
            <Typography variant="h6" sx={{ lineHeight: "20px" }}>
              {user?.name}
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: "20px" }}>
              <b>Purpose of using</b>: {user?.purpose}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            {color === "primary" && (
              <Button
                variant="contained"
                color="error"
                sx={{ textTransform: "none", minWidth: 120 }}
                startIcon={<DoDisturbOutlinedIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDenyRequest(user?._id);
                }}
              >
                Deny
              </Button>
            )}
            <Button
              variant="contained"
              color={color}
              sx={{
                textTransform: "none",
                minWidth: 120,
                cursor: color === "success" && "default",
              }}
              startIcon={color === "success" ? <VerifiedIcon /> : <HourglassEmptyOutlinedIcon />}
              onClick={
                color === "primary"
                  ? (e) => {
                      e.stopPropagation();
                      handleApproveRequest(user?._id);
                    }
                  : (e) => {
                      e.stopPropagation();
                    }
              }
            >
              {buttonText}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <UserRequestDetailsModal open={open} setOpen={setOpen} user={user} />
    </>
  );
};

export default FormCreatorRequestCard;
