import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Grid, ThemeProvider, Typography } from "@mui/material";

import { Constants, QUESTION_TYPE } from "../../config/Constants";
import { GET, GETwithoutAuth, POSTwithoutAuth, PUTwithoutAuth } from "../../config/Api";
import SectionTitle from "./PreviewComponent/SectionTitle";
import PreviewComponent from "./PreviewComponent";
import ButtonMain from "../Buttons/ButtonMain";
import { ToastNotification } from "../ToastNotification";
import ImageBanner from "./PreviewComponent/ImageBanner";
import { THEME } from "../Themes/theme";
import SuccessfulSubmission from "./PreviewComponent/SuccessfulSubmission";
import { convertQuestionData, sortComponentByOrder } from "../../utils";
import PreviewEmailModal from "../Modals/PreviewEmailModal";

const FormPreview = () => {
  /* --- HOOKS --- */
  const { slug } = useParams();
  const [searchParams] = useSearchParams();

  const toastRef = useRef();
  // let { accessToken } = useSelector((state) => state.userLoginDetails);
  const { success } = useSelector((state) => state.userLoginDetails);
  /* --- STATES --- */

  const [formConfig, setFormConfig] = useState([]);
  let allSections = formConfig?.section;
  const [questionData, setQuestionData] = useState([]);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [activityId, setActivityId] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [email, setEmail] = useState("");
  const submisstionMessage = "Form Submitted Successfully!";
  const [componentData, setcomponentData] = useState({});



  const memoizedQuestionData = useMemo(() => questionData, [questionData]);
  const MemoizedSectionTitle = React.memo(
    ({ title, description }) => <SectionTitle title={title} description={description} />,
    (prevProps, nextProps) =>
      prevProps.title === nextProps.title && prevProps.description === nextProps.description,
  );

  /* --- LIFE CYCLES --- */
  useEffect(() => {
    getFormBySlug(slug);
    if (success) {
      getActivitybySlug(slug);
    }
  }, [slug]);


  /* --- METHODS --- */
  const getFormBySlug = async (form_slug) => {
    GETwithoutAuth(`${Constants.form_activity}/get_preview_form/${form_slug}?email=${email}`)
      .then((res) => {

        if (res.data.success) {
          setFormConfig(sortComponentByOrder(res.data.form[0]));
          setcomponentData(convertQuestionData(sortComponentByOrder(res.data.form[0]).section, sectionIndex));
          res.data.form[0]?.require_login && setOpen(true);
        }
      })
      .catch((err) => toastRef.current.showToastMessage(err.message, "error"));
  };

  const getActivitybySlug = async (form_slug) => {
    GET(`${Constants.form_activity}/get_activity/${form_slug}`)
      .then((res) => {

        if (res.data.success && res.data?.activity?.length > 0) {
          setQuestionData(res.data.activity_details.form_activity);
        }
      })
      .catch((err) => toastRef?.current?.showToastMessage(err.message, "error"));
  };

  const handleFormChange = useCallback((e, qType) => {
    const { name, value } = e.target;

    setQuestionData((prevState) => ({
      ...prevState,
      [name]: {
        response: value,
        type: qType,
      },
    }));
  }, []);

  const handleMultiChoiceChange = useCallback((e, qType) => {
    const { name, value } = e.target;

    setQuestionData((prevState) => {
      const currentArray = prevState[name]?.response || [];
      const updatedValue = currentArray.includes(value);

      const updatedResponse = updatedValue
        ? currentArray.filter((item) => item !== value)
        : [...currentArray, value];

      return {
        ...prevState,
        [name]: {
          response: updatedResponse,
          type: qType,
        },
      };
    });
  }, []);

  function isRequiredAnswered() {
    let allRequired = allSections[sectionIndex].component.map((item) => {
      if (item.type === QUESTION_TYPE && item.question.is_required) {
        return item._id;
      }
      return null;
    });

    allRequired = allRequired.filter((value) => value !== null);
    const allIdsExist = allRequired.every((id) => {
      return (
        questionData.hasOwnProperty(id) &&
        (questionData[id].response !== "" || questionData[id].response.length > 0)
      );
    });
    if (!allIdsExist) {
      throw new Error("Not all required fields are filled.");
    }

    return true;
  }

  const handlePrevious = () => {
    setSectionIndex((prev) => prev - 1);
    setcomponentData(convertQuestionData(allSections, sectionIndex - 1));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      isRequiredAnswered(allSections[sectionIndex]._id);

      const queryParamsObject = {};

      // Loop through all the keys in searchParams
      for (const key of searchParams.keys()) {
        // Get the value for each key and add it to the object
        queryParamsObject[key] = searchParams.get(key);
      }
      for (let key in componentData) {
        Object.keys(questionData).includes(key) && delete componentData.key;
      }
      let form_activity = {
        form_id: formConfig._id,
        email,
        unique_id: uniqueId,
        section_id: allSections[sectionIndex]?._id,
        activity: { ...componentData, ...questionData },
        url_params: queryParamsObject,
        is_submitted: allSections.length - 1 === sectionIndex ? true : false,
        enable_multiple_submission: formConfig?.enable_multiple_submission,
        require_login: formConfig.require_login,
      };

      if (activityId) {
        form_activity.activity_id = activityId;
        PUTwithoutAuth(`${Constants.form_activity}/save`, form_activity)
          .then((res) => {
            if (res.data.success) {
              if (allSections.length - 1 === sectionIndex) {
                setIsFormSubmitted(true);
                toastRef.current.showToastMessage(submisstionMessage, "success");
              } else {
                setSectionIndex((prev) => prev + 1);
                setcomponentData(convertQuestionData(allSections, sectionIndex + 1));
                toastRef.current.showToastMessage(res.data.message, "success");
              }
            }
          })
          .catch((err) => toastRef.current.showToastMessage(err.message, "error"));
      } else {
        POSTwithoutAuth(`${Constants.form_activity}/save`, form_activity)
          .then((res) => {
            setActivityId(res.data.activity_id);
            if (res.data.success) {
              if (allSections.length - 1 === sectionIndex) {
                setIsFormSubmitted(true);
                toastRef.current.showToastMessage(submisstionMessage, "success");
              } else {
                setSectionIndex((prev) => prev + 1);
                setcomponentData(convertQuestionData(allSections, sectionIndex + 1));
                toastRef.current.showToastMessage(res.data.message, "success");
              }
            }
          })
          .catch((err) => toastRef.current.showToastMessage(err.message, "error"));
      }
    } catch (err) {
      toastRef.current.showToastMessage(err.message, "warning");
    }
  };

  /* --- COMPONENT RETURN --- */
  return (
    allSections && (
      <ThemeProvider theme={THEME}>
        {formConfig?.is_active ? (
          <Grid
            container
            className="formPrevewWrapper"
            flexDirection={"column"}
            justifyContent={isFormSubmitted ? "center" : "flex-start"}
            paddingX={isFormSubmitted ? "1rem" : 0}
          >
            {!isFormSubmitted ? (
              <>
                {formConfig.form_banner ? (
                  <ImageBanner
                    bannerImage={formConfig.form_banner}
                    formTitle={formConfig.form_title}
                  />
                ) : <Typography variant="h5" textAlign={"center"} padding={"1rem"}>{formConfig?.form_title}</Typography>}
                <Box className="componentPreview">
                  <MemoizedSectionTitle
                    title={allSections[sectionIndex].section_title}
                    description={allSections[sectionIndex].section_description}
                  />
                  {allSections[sectionIndex]?.component.map((component, index) => (
                    <PreviewComponent
                      key={index}
                      componentData={component}
                      questionData={memoizedQuestionData}
                      setQuestionData={setQuestionData}
                      handleFormChange={handleFormChange}
                      handleMultiChoiceChange={handleMultiChoiceChange}
                    />
                  ))}
                </Box>
                <Grid item sx={{ width: "100%", maxWidth: "800px", height: "100%" }}>
                  <Grid
                    container
                    flexDirection={"row"}
                    justifyContent={sectionIndex !== 0 ? "space-between" : "flex-end"}
                  >
                    {sectionIndex !== 0 && (
                      <Grid item width={"40%"}>
                        <ButtonMain
                          variant="orange"
                          style={{ width: "100%" }}
                          onClick={handlePrevious}
                        >
                          Previous
                        </ButtonMain>
                      </Grid>
                    )}
                    <Grid item width={"40%"}>
                      <ButtonMain
                        variant="orange"
                        style={{ width: "100%" }}
                        onClick={handleFormSubmit}
                      >
                        {allSections.length - 1 === sectionIndex ? "Submit" : "Save and Next"}
                      </ButtonMain>
                    </Grid>
                  </Grid>
                </Grid>
                <ToastNotification ref={toastRef} duration={2000} />
              </>
            ) : (
              <SuccessfulSubmission message={submisstionMessage} />
            )}
          </Grid>
        ) : (
          <Box
            sx={{
              bgcolor: "#fee2e2",
              textAlign: "center",
              height: "100%",
              width: "100%",
              py: 2,
              border: "1px solid #ef4444",
            }}
          >
            <Typography variant="h5">This form has been deactivated.</Typography>
          </Box>
        )}
        {formConfig.require_login && <PreviewEmailModal open={open} setOpen={setOpen} uniqueId={uniqueId} email={email} setUniqueId={setUniqueId} setEmail={setEmail} setFormConfig={setFormConfig} formConfig={formConfig} />}
      </ThemeProvider>
    )
  );
};

export default FormPreview;
