import React from "react";
import FormTitlePreview from "./FormTitlePreview";
import QuestionPreview from "./QuestionPreview";
import ImagePreview from "./ImagePreview";
import VideoPreview from "./VideoPreview";
import { IMAGE_TYPE, QUESTION_TYPE, TITLE_TYPE, VIDEO_TYPE } from "../../../config/Constants";

const FormTitlePreviewMemoized = React.memo(FormTitlePreview);
const QuestionPreviewMemoized = React.memo(QuestionPreview);
const ImagePreviewMemoized = React.memo(ImagePreview);
const VideoPreviewMemoized = React.memo(VideoPreview);

const PreviewComponent = ({
  componentData,
  questionData,
  setQuestionData,
  handleFormChange,
  handleMultiChoiceChange,
}) => {
  switch (componentData.type) {
    case TITLE_TYPE:
      return (
        <FormTitlePreviewMemoized
          title={componentData.title}
          description={componentData.description}
        />
      );
    case QUESTION_TYPE:
      return (
        <QuestionPreviewMemoized
          component_id={componentData._id}
          question={componentData.question}
          questionData={questionData}
          setQuestionData={setQuestionData}
          handleFormChange={handleFormChange}
          handleMultiChoiceChange={handleMultiChoiceChange}
        />
      );
    case IMAGE_TYPE:
      return <ImagePreviewMemoized img_url={componentData.media_url} />;
    case VIDEO_TYPE:
      return <VideoPreviewMemoized img_url={componentData.media_url} />;
    default:
      return null;
  }
};

export default PreviewComponent;
