import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import "./header.scss";

const LogoComponent = () => {
  let navigate = useNavigate();

  return (
    <Grid item className="logoWrapper" onClick={() => navigate("/")}>
      <img
        className="logo_main"
        src={`${process.env.REACT_APP_CLOUDFRONT}/fs-static/images/site/logo.png`}
        alt="fs-logo"
      />
      <Typography className="logo_typo" variant="h6">
        FramerSpace Forms
      </Typography>
    </Grid>
  );
};

export default LogoComponent;
