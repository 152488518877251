import React from "react";
import { Paper, Typography } from "@mui/material";

const QuestionTitle = ({ title, required }) => {
  return (
    <Paper elevation={2} className="questionTitlePaper">
      <div className="questionTitleHighlight" />
      <Typography fontWeight={"bold"}>
        {required && <span style={{ color: "red" }}>*</span>}
        &nbsp;{title}
      </Typography>
    </Paper>
  );
};

export default QuestionTitle;
