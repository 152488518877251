import React, { useEffect, useState } from "react";
import { Button, Grid, Paper } from "@mui/material";
import "../Form.scss";
import CreateAndEditSectionModal from "../../Modals/CreateAndEditSectionModal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const SectionTitle = ({ handleFormSection, sectionDetail }) => {
  /* --- STATES --- */
  const [formTitleData, setFormTitleData] = useState({
    title: "",
    description: "",
    banner: "",
    section_id: "",
  });
  const [open, setOpen] = useState(false);

  /* --- LIFE CYCLES --- */

  useEffect(() => {
    if (sectionDetail) {
      const initialData = {
        section_id: sectionDetail._id,
        title: sectionDetail.section_title,
        description: sectionDetail.section_description,
      };
      setFormTitleData(initialData);
    } else {
      setFormTitleData({
        title: "",
        description: "",
        banner: "",
        section_id: "",
      });
    }
  }, [sectionDetail]);

  const handleInputChange = (field, value) => {
    setFormTitleData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <>
      <Paper elevation={2} className="createSectionTitlePaper">
        <Grid container>
          <Grid item xs={12}>
            <h4>Section Title:</h4>
            <div dangerouslySetInnerHTML={{ __html: formTitleData.title }} />
          </Grid>
          <Grid item xs={12}>
            <h4>Section Description:</h4>
            <div dangerouslySetInnerHTML={{ __html: formTitleData.description }} />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              startIcon={<EditOutlinedIcon />}
              onClick={() => setOpen(true)}
            >
              Edit Section
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <CreateAndEditSectionModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        isEdit={true}
        formTitleData={formTitleData}
        handleInputChange={handleInputChange}
        handleFormSection={handleFormSection}
      />
    </>
  );
};

export default SectionTitle;
