import React from "react";
import { Grid, Paper } from "@mui/material";
import "./Form.scss";
import { useLocation } from "react-router-dom";

const ComponentContainer = ({
  children,
  onMouseHover,
  padding = 2,
  onMouseEnter,
  onMouseLeave,
  isDragging,
  isEdit,
}) => {
  const location = useLocation();
  return (
    <Paper
      component="form"
      elevation={2}
      className="componentContainer"
      sx={{
        borderRadius: "8px",
        backgroundColor: isDragging ? "#f7dada" : "",
        marginBottom: "1rem",
        boxShadow:
          !isEdit && location.pathname.startsWith("/create") ? "1px 1px 7px #01a77dbd" : undefined,
        border:
          !isEdit && location.pathname.startsWith("/create") ? "2px solid #01a77dbd" : undefined,
      }}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <Grid container padding={padding} gap={2} flexDirection={"column"}>
        {children}
      </Grid>
    </Paper>
  );
};

export default ComponentContainer;
