import React from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { QuestionTypes } from "../../../config/Constants";

const QuestionTitle = ({
  questionData,
  fieldValue,
  handleQuestionTypeSelect,
  handleTitleChange,
}) => {
  return (
    <Grid
      item
      lg={12}
      md={12}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid item lg={8} md={8}>
        <TextField
          label="Question Title"
          fullWidth
          value={questionData.question_title}
          onChange={handleTitleChange}
        />
      </Grid>
      <FiberManualRecordIcon sx={{ height: "10px", fill: "#cecece" }} />
      <Grid item lg={4} md={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={fieldValue}
            label="Input"
            onChange={handleQuestionTypeSelect}
          >
            {QuestionTypes.map((type) => (
              <MenuItem value={type.key} key={type.index}>
                {type.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default QuestionTitle;
