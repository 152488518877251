import React from "react";
import "./ButtonMain.scss";
import { Button } from "@mui/material";

const ButtonMain = ({ className, children, variant = "primary", onClick, style, disabled }) => {
  return (
    <Button
      variant="contained"
      // className={`buttonMain ${disabled ? "buttonDisabled" : null} ${variant} ${className}`}
      onClick={onClick}
      style={style}
      disabled={disabled}
      sx={{ textTransform: "none" }}
    >
      {children}
    </Button>
  );
};

export default ButtonMain;
