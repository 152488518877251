import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ComponentContainer from "../ComponentContainer";
import FSTextEditor from "./FSTextEditor";

import "../Form.scss";
import FormAction from "./FormAction";
import { POST, PUT } from "../../../config/Api";
import { Constants, TITLE_TYPE } from "../../../config/Constants";

const FormTitle = ({ section_id, onDelete, componentData, toastRef, getFormBySlug }) => {
  /* --- HOOKS --- */
  /* --- STATES --- */
  const [formTitleData, setFormTitleData] = useState({
    title: "",
    description: "",
    banner: "",
    section_id: "",
    is_required: false,
    _id: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  /* --- LIFE CYCLES --- */

  useEffect(() => {
    setFormTitleData({ ...formTitleData, section_id: section_id });
  }, [section_id]);

  useEffect(() => {
    if (componentData) {
      setIsEdit(true);
      setFormTitleData({
        ...formTitleData,
        form_id: componentData.form_id,
        _id: componentData._id,
        section_id: componentData.section_id,
        title: componentData.title,
        description: componentData.description,
        order: componentData.order,
      });
    }
  }, [componentData]);

  // <--- METHODS --->

  const handleFormTitle = async (e) => {
    e.preventDefault();
    let body = {
      form_id: formTitleData.form_id,
      section_id: formTitleData.section_id,
      type: TITLE_TYPE,
      title: formTitleData.title,
      description: formTitleData.description,
      order: formTitleData.order,
    };
    if (formTitleData._id) {
      body.component_id = formTitleData._id;
      await PUT(`${Constants.form}/component`, body)
        .then(async (res) => {
          setIsEdit(false);
          getFormBySlug();
          toastRef.current.showToastMessage(res.data.message, "success");
        })
        .catch((err) => toastRef.current.showToastMessage(err.message, "error"));
    } else {
      await POST(`${Constants.form}/component`, body)
        .then(async (res) => {
          getFormBySlug();
          setIsEdit(false);
          setFormTitleData({
            ...formTitleData,
            _id: res.data.config_id,
          });
          toastRef.current.showToastMessage(res.data.message, "success");
        })
        .catch((err) => toastRef.current.showToastMessage(err.message, "error"));
    }
  };

  return (
    <ComponentContainer>
      <Grid item className="form_title">
        <FSTextEditor
          placeholder={"Title"}
          value={formTitleData.title}
          onChange={(newValue) => {
            setIsEdit(false);
            setFormTitleData({ ...formTitleData, title: newValue });
          }}
        />
      </Grid>

      <Grid item className="form_description">
        <FSTextEditor
          placeholder={"Description (Optional)"}
          value={formTitleData.description}
          onChange={(newValue) => {
            setIsEdit(false);
            setFormTitleData({ ...formTitleData, description: newValue });
          }}
        />
      </Grid>

      <FormAction
        saveVisible={true}
        saveDisabled={isEdit}
        onSave={handleFormTitle}
        onCopy={""}
        onDelete={() => {
          onDelete(componentData.order, formTitleData._id);
        }}
        hideDuplicate={false}
      />
    </ComponentContainer>
  );
};

export default FormTitle;
