import React from "react";
import { Box, Grid } from "@mui/material";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import "../formPreview.scss";

const ImageBanner = ({ bannerImage, formTitle }) => {
  /* --- COMPONENT RETURN --- */
  return (
    <Grid item className="previewBanner">
      <img className="previerBannerImg" src={`${cloudfrontUrl}/${bannerImage}`} alt={bannerImage} />
      {formTitle && <Box className="overlay-text-preview">{formTitle}</Box>}
    </Grid>
  );
};

export default ImageBanner;
