import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Divider, Grid, Alert, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { removeHTMLElement } from "../../utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { saveFormId } from "../../redux/action";
import FullwidthButton from "../CreateForm/FormComponents/FullwidthButton";
import { POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { ToastNotification } from "../ToastNotification";

// Function to reorder sections
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function SectionReorderModal({ open, setOpen }) {
  const [isVisible, setIsVisible] = useState(false);
  const [disabled, setDisable] = useState(false);
  const formConfig = useSelector((state) => state.form_config);
  const [formLocalConfig, setformLocalConfig] = useState(formConfig);
  const dispatch = useDispatch();
  const toastRef = useRef();
  const isMediumScreen = useMediaQuery("(max-width:900px)");

  useEffect(() => {
    setformLocalConfig(formConfig);
  }, [formConfig]);

  // Function to handle the end of a drag event
  const onDragEnd = (result) => {
    if (!result.destination) {
      setIsVisible(false);
      return;
    }

    const reorderedSections = reorder(
      formLocalConfig?.section?.filter((sec) => Boolean(sec?.section_title)),
      result?.source?.index,
      result?.destination?.index,
    );

    // Update section_order based on the new position
    const updatedSectionsWithOrder = reorderedSections.map((section, index) => ({
      ...section,
      section_order: index + 1,
    }));

    const updatedFormConfig = {
      ...formLocalConfig,
      section: updatedSectionsWithOrder,
    };
    setformLocalConfig(updatedFormConfig);
    // Dispatch the updated form configuration
    // dispatch(saveFormId(updatedFormConfig));
    setIsVisible(true);
  };

  const updateSectionOrder = async () => {
    try {
      let payload = {
        updatedSections: formLocalConfig?.section,
      };
      setDisable(true);
      const response = await POST(
        `${Constants.update_section_order}/${formLocalConfig?._id}`,
        payload,
      );
      if (response.data.success) {
        toastRef.current.showToastMessage(response.data.message, "success");
        dispatch(saveFormId(formLocalConfig));
        setIsVisible(false);
        setOpen(false);
        setDisable(false);
      } else {
        toastRef.current.showToastMessage(response.data.message, "error");
        setDisable(false);
        setIsVisible(true);
      }
    } catch (error) {
      toastRef.current.showToastMessage(error.response.data.message, "error");
      setDisable(false);
      setIsVisible(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "none", height: "100vh" } }}
    >
      <DialogTitle padding={"0rem 0rem"}>
        <Grid container alignItems="center" spacing={1}>
          {/* Reorder Section */}
          <Grid item xs={11} md={2}>
            <Typography
              variant="h6"
              component="div"
              style={{ textAlign: "center", fontWeight: 600 }}
            >
              Reorder Section
            </Typography>
          </Grid>
          {isMediumScreen && (
            <Grid item xs={1}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {
                      setformLocalConfig(formConfig);
                      setOpen(false);
                      setIsVisible(true);
                    }}
                    aria-label="close"
                    sx={{ backgroundColor: "#e4e6eb" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Alert */}
          <Grid item xs={12} md={8}>
            <Alert severity="info" sx={{ alignItems: "center" }}>
              Drag and drop to change the order of the section.
            </Alert>
          </Grid>

          {/* Save Button */}
          <Grid item xs={2} md={1}>
            <Button
              onClick={updateSectionOrder}
              color="primary"
              variant="contained"
              fullWidth
              sx={{ textTransform: "none", padding: "0.3rem 1.5rem", fontWeight: 600 }}
              disabled={disabled || !isVisible}
            >
              Save
            </Button>
          </Grid>

          {/* Close Icon */}
          {!isMediumScreen && (
            <Grid item xs={2} md={1}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {
                      setformLocalConfig(formConfig);
                      setOpen(false);
                      setIsVisible(true);
                    }}
                    aria-label="close"
                    sx={{ backgroundColor: "#e4e6eb" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogTitle>

      <Divider />
      <DialogContent>
        <DialogContentText>
          <Grid
            container
            sx={{ paddingY: "1rem", paddingX: "2rem" }}
            flexDirection={"column"}
            gap={"1rem"}
            className="createSectionContainer"
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="sections">
                {(provided) => (
                  <Grid
                    item
                    className="createSectionScroll1"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: "100%" }}
                  >
                    {formLocalConfig?.section?.length > 0 &&
                      formLocalConfig?.section?.map((section, i) => (
                        <Draggable key={i} draggableId={`${i}`} index={i}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                marginBottom: "1rem",
                              }}
                            >
                              {section?.section_title && (
                                <FullwidthButton
                                  label={
                                    section?.section_title !== ""
                                      ? removeHTMLElement(section?.section_title)
                                      : "Unnamed Title"
                                  }
                                  dragHandleProps={provided.dragHandleProps}
                                  isDragging={snapshot.isDragging}
                                  sectionOrder={section?.section_order}
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <ToastNotification ref={toastRef} duration={3000} />
    </Dialog>
  );
}

export default SectionReorderModal;
