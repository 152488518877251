import {
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CreateFormHeader from "../Header/CreateFormHeader";
import { useNavigate, useParams } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import { saveFormId } from "../../redux/action";
import { removeHTMLElement, sortComponentByOrder } from "../../utils";
import { GET, PUT } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import "./setting.scss";
import { ToastNotification } from "../ToastNotification";
import ButtonMain from "../Buttons/ButtonMain";

const SettingLayout = () => {
  const [enableMultipleSubmission, setEnableMultipleSubmission] = useState(false);
  const [enableRandomization, setEnableRandomization] = useState(false);
  const [identityRequired, setIdentityRequired] = useState(false);
  const [requireLogin, setRequireLogin] = useState(false);
  const [mandatorySections, setMandatorySections] = useState([]);
  const [sectionToAllocate, setSectionToAllocate] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);

  const formConfig = useSelector((state) => state.form_config);
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastRef = useRef();
  let loginDetails = useSelector((state) => state.userLoginDetails);

  useEffect(() => {
    if (!loginDetails?.user?.isFormCreator === 1) {
      navigate("/");
    }
  }, [loginDetails]);

  useEffect(() => {
    getFormBySlug(slug);
  }, [slug, dataSubmitted]);

  useEffect(() => {
    if (formConfig) {
      setEnableMultipleSubmission(formConfig.enable_multiple_submission);
      setEnableRandomization(formConfig.is_random_allocation);
      setIdentityRequired(formConfig.identity_required);
      setMandatorySections(
        formConfig.section?.filter((section) => section.is_mandatory_section) || [],
      );
      setSectionToAllocate(formConfig?.number_of_section_to_allocate);
      setRequireLogin(formConfig.require_login);
    }
  }, [formConfig]);

  useEffect(() => {
    if (!requireLogin) {
      setIdentityRequired(false);
      setEnableMultipleSubmission(false);
    }
  }, [requireLogin]);

  /* --- METHODS --- */

  const getFormBySlug = async () => {
    GET(`${Constants.form_activity}/get_form/${slug}`)
      .then((res) => {
        if (res.data.success) {
          let validSections = res.data.form[0].section.filter((el) => !!el?.section_title);
          let modifiedFormData = res.data.form[0];
          modifiedFormData.section = validSections;
          dispatch(saveFormId(sortComponentByOrder(modifiedFormData)));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleMenuItemClick = (item) => {
    const itemId = item._id;
    const isSelected = mandatorySections.some((selectedItem) => selectedItem._id === itemId);

    setIsEdit(true);
    // if (mandatorySections.length + sectionToAllocate >= formConfig.section.length) {
    if (isSelected) {
      // Item exists, remove it
      const updatedMandatorySections = mandatorySections.filter(
        (selectedItem) => selectedItem._id !== itemId,
      );

      setMandatorySections(updatedMandatorySections);
    } else {
      // Item does not exist, add it
      if (mandatorySections.length + sectionToAllocate === formConfig.section.length) {
        return;
      } else {
        setMandatorySections([...mandatorySections, item]);
      }
    }
  };

  const handleRadioChange = (event, setter) => {
    setIsEdit(true);
    const value = event.target.value === "true";
    setter(value);
  };

  const handleSelectChange = (event, setter) => {
    setIsEdit(true);
    const value = event.target.value;
    setter(value);
  };

  const handleSaveFormSetting = async () => {
    if (enableRandomization && !mandatorySections.length) {
      setError(true);
      return; // Stop the function execution if there's an error
    }
    // setDisable(true);
    let payload = {
      slug,
      enable_multiple_submission: enableMultipleSubmission,
      is_random_allocation: enableRandomization,
      identity_required: identityRequired,
      mandatory_section: mandatorySections,
      number_of_section_to_displayed: sectionToAllocate + mandatorySections.length,
      number_of_section_to_allocate: sectionToAllocate,
      require_login: requireLogin,
    };
    try {
      const response = await PUT(`${Constants.form}/update_form_setting`, payload);
      setDataSubmitted(!dataSubmitted);
      setDisable(false);
      toastRef.current.showToastMessage(response?.data?.message, "success");
      setError(false);
    } catch (error) {
      setDisable(false);
      setError(false);
      toastRef.current.showToastMessage(error?.response?.data?.message, "error");
    }
  };

  const validSectionOptions =
    formConfig?.section?.length > mandatorySections.length
      ? formConfig?.section
          ?.slice(0, formConfig?.section.length - mandatorySections.length)
          .map((_, index) => index + 1)
      : [];

  if (Object.keys(loginDetails).length === 0) {
    return "";
  }
  return (
    <div>
      <CreateFormHeader>
        <div className="settingContainer">
          <div>
            <Typography variant="h2" fontSize={"16px"} fontWeight={600}>
              Enable random allocation of sections :
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={String(enableRandomization)}
                onChange={(e) => handleRadioChange(e, setEnableRandomization)}
              >
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          {enableRandomization && (
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">Select Mandatory Section</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={mandatorySections}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Select Mandatory Section" />
                  }
                  renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((value) => (
                        <Chip
                          key={value?._id}
                          label={removeHTMLElement(value?.section_title)}
                          style={{ margin: 2 }}
                        />
                      ))}
                    </div>
                  )}
                >
                  {formConfig?.section?.map((item, index) => (
                    <MenuItem key={index} value={item} onClick={() => handleMenuItemClick(item)}>
                      {removeHTMLElement(item?.section_title)}
                    </MenuItem>
                  ))}
                </Select>
                {error && (
                  <FormHelperText style={{ color: "red" }}>
                    Please select at least one mandatory section.
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          )}

          {enableRandomization && (
            <div>
              <FormControl fullWidth>
                <InputLabel id="number-of-sections-displayed-label">
                  Number of sections to be randomly allocated
                </InputLabel>
                <Select
                  labelId="number-of-sections-displayed-label"
                  id="number-of-sections-displayed"
                  value={sectionToAllocate ?? validSectionOptions[0]}
                  onChange={(e) => handleSelectChange(e, setSectionToAllocate)}
                  label="Number of sections to be randomly allocated"
                >
                  {/* <MenuItem value={0}>0</MenuItem> */}
                  {validSectionOptions?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <Typography variant="h2" fontSize={"16px"} fontWeight={600}>
            Total Section displayed to the user is {sectionToAllocate + mandatorySections.length}
          </Typography>

          <div>
            <Typography variant="body1" fontSize={"16px"} fontWeight={600}>
              Require Identification by framerspace uniqueid and/or email :
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={String(requireLogin)}
                onChange={(e) => handleRadioChange(e, setRequireLogin)}
              >
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <Typography variant="body1" fontSize={"16px"} fontWeight={600}>
              Maintain the same section allocations for the user in future :
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={String(identityRequired)}
                onChange={(e) => handleRadioChange(e, setIdentityRequired)}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                  disabled={!requireLogin}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                  disabled={!requireLogin}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div>
            <Typography variant="h2" fontSize={"16px"} fontWeight={600}>
              Enable multiple submission :
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={String(enableMultipleSubmission)}
                onChange={(e) => handleRadioChange(e, setEnableMultipleSubmission)}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                  disabled={!requireLogin}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                  disabled={!requireLogin}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div item xs={12}>
            <ButtonMain onClick={handleSaveFormSetting} disabled={!isEdit || disable}>
              Save Setting
            </ButtonMain>
          </div>
        </div>
        <ToastNotification ref={toastRef} duration={2000} />
      </CreateFormHeader>
    </div>
  );
};

export default SettingLayout;
