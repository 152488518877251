import React from "react";
import { Button, Grid, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CodeIcon from "@mui/icons-material/Code";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const CreateHeaderOptions = ({
  onPreview,
  onLinkCopy,
  onCodeCopy,
  handleLogout,
  onJoiningCodeCopy,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();

  const isSettingPage = location.pathname.includes("setting");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!isSettingPage && (
        <Grid
          item
          display={"flex"}
          gap={"0.2rem"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          <IosShareOutlinedIcon color="secondary" fontSize="small" />
          <Typography color="white" sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}>
            Share
          </Typography>
        </Grid>
      )}
      {!isSettingPage && (
        <Grid
          item
          display={"flex"}
          gap={"0.2rem"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={onPreview}
        >
          <RemoveRedEyeOutlinedIcon color="secondary" fontSize="small" />
          <Typography color="white" sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}>
            Preview
          </Typography>
        </Grid>
      )}
      {!isSettingPage && (
        <Grid
          item
          display={"flex"}
          gap={"0.2rem"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(`/setting/${slug}`)}
        >
          <SettingsOutlinedIcon color="secondary" fontSize="small" />
          <Typography color="white" sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}>
            Setting
          </Typography>
        </Grid>
      )}
      {isSettingPage && (
        <Grid
          item
          display={"flex"}
          gap={"0.2rem"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(`/create/${slug}`)}
        >
          <EditOutlinedIcon color="secondary" fontSize="small" />
          <Typography color="white" sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}>
            Edit Form
          </Typography>
        </Grid>
      )}

      <Button variant="contained" onClick={handleLogout} color="primary">
        Logout
      </Button>
      <Menu
        // 1
        anchorEl={anchorEl}
        open={open}
        // onClick={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {onLinkCopy && (
          <MenuItem onClick={onLinkCopy}>
            {" "}
            <ListItemIcon>
              <InsertLinkIcon fontSize="small" />
            </ListItemIcon>
            Copy Link
          </MenuItem>
        )}
        <MenuItem onClick={onJoiningCodeCopy}>
          {" "}
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          Copy Joining Code
        </MenuItem>
        {onCodeCopy && (
          <MenuItem onClick={onCodeCopy}>
            <ListItemIcon>
              <CodeIcon fontSize="small" />
            </ListItemIcon>
            Copy Embed Code
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default CreateHeaderOptions;
