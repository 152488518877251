import React, { useEffect, useState, useRef, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import { DEL, GET, POST, PUT } from "../../config/Api";
import { Constants, IMAGE_TYPE, QUESTION_TYPE, TITLE_TYPE } from "../../config/Constants";
import SectionTitle from "./FormComponents/SectionTitle";
import FormToolbar from "./FormComponents/FormToolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewSectionAction,
  saveFormId,
  updateFormConfigAction,
  updateSectionAction,
} from "../../redux/action";
import {
  ADD_NEW_COMPONENT,
  COPY_COMPONENT,
  FORM_CONFIG_RESET,
  REMOVE_COMPONENT,
} from "../../redux/actionTypes";
import { ToastNotification } from "../ToastNotification";
import FormBanner from "./FormComponents/FormBanner";

import { removeHTMLElement, sortComponentByOrder } from "../../utils";
import CreateFormHeader from "../Header/CreateFormHeader";
import SectionButton from "./FormComponents/SectionButton";
import CreateSectionButton from "./FormComponents/CreateSectionButton";
import DialogBox from "../Modals/DialogBox";
import DraggableContent from "./DraggableContent";
import CreateAndEditSectionModal from "../Modals/CreateAndEditSectionModal";
import ReorderSectionButton from "./FormComponents/ReorderSectionButton";

const CreateFormLayout = () => {
  /* --- HOOKS --- */
  const [loading, setLoading] = useState(false);
  const { slug } = useParams();
  const dispatch = useDispatch();
  const formConfig = useSelector((state) => state.form_config);
  let allSections = formConfig.section;
  const toastRef = useRef();
  const navigate = useNavigate();
  let loginDetails = useSelector((state) => state.userLoginDetails);

  /* --- STATES --- */
  const [sectionIndex, setSectionIndex] = useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState({
    dialogTitle: "",
    dialogMessage: "",
    cancelMessage: "",
    agreeMessage: "",
    type: "",
  });
  const [formTitleData, setFormTitleData] = useState({
    title: "",
    description: "",
    banner: "",
    section_id: "",
  });
  const FORM_ACTION = { DELETE_COMPONENT: "DELETE_COMPONENT" };

  /* --- LIFE CYCLES --- */

  useEffect(() => {
    getFormBySlug(slug);
    updateEvent(slug);
    return () => {
      dispatch({ type: FORM_CONFIG_RESET });
    };
  }, [slug]);

  useEffect(() => {
    if (!loginDetails?.user?.isFormCreator === 1) {
      navigate("/");
    }
  }, [loginDetails]);

  /* --- METHODS --- */

  const getFormBySlug = async () => {
    GET(`${Constants.form_activity}/get_form/${slug}`)
      .then((res) => {
        if (res.data.success) {
          let validSections = res.data.form[0].section.filter((el) => !!el?.section_title);
          let modifiedFormData = res.data.form[0];
          modifiedFormData.section = validSections;
          dispatch(saveFormId(sortComponentByOrder(modifiedFormData)));
        }
      })
      .catch((err) => console.log(err));
  };

  const updateEvent = (slug) => {
    let body = {
      form_slug: slug,
    };
    PUT(`${Constants.form}/form_date_update`, body);
  };

  const handleFormSection = async (sectionData) => {
    if (sectionData.title !== "") {
      if (sectionData.section_id) {
        let body = {
          form_id: formConfig._id,
          section: {
            _id: sectionData.section_id,
            section_title: sectionData.title,
            section_description: sectionData.description,
          },
        };
        await PUT(`${Constants.form}/section`, body)
          .then((res) => {
            if (res.data.success) {
              toastRef.current.showToastMessage(res.data.message, "success");
              dispatch(updateSectionAction(body.section));
            }
          })
          .catch((err) => toastRef.current.showToastMessage(err.message, "err"));
      } else {
        let body = {
          form_id: formConfig._id,
          section: {
            section_title: sectionData.title,
            section_description: sectionData.description,
          },
        };
        await POST(`${Constants.form}/section`, body)
          .then((res) => {
            if (res.data.success) {
              toastRef.current.showToastMessage(res.data.message, "success");
              let payload = {
                ...body.section,
                _id: res.data.section_id,
                section_order: res.data.section_order,
                // component: [],
                // type: "new",
              };
              setSectionIndex(allSections[0]?.section_title ? allSections.length : 0);
              dispatch(AddNewSectionAction(payload));
            }
          })
          .catch((err) => toastRef.current.showToastMessage(err.message, "err"));
      }
    } else {
      toastRef.current.showToastMessage("Section Title cannot be empty.", "warning");
    }
  };

  const handeFormBannerUpload = async (banner) => {
    let formData = new FormData();

    formData.append("file", banner);
    // formData.append("form_title", title);
    formData.append("form_id", formConfig._id);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    await PUT(`${Constants.form}/form`, formData, config)
      .then(async (res) => {
        dispatch(updateFormConfigAction(res.data.form_config));
        toastRef.current.showToastMessage(res.data.message, "success");
      })
      .catch((err) => {
        toastRef.current.showToastMessage(err.message, "error");
      });
  };

  function handleAddNewComponent(section_id, previousComponentOrder, componentType) {
    let payload = {
      section_id: section_id,
      form_id: formConfig._id,
      type: componentType,
      newComponentOrder: previousComponentOrder,
    };
    dispatch({ type: ADD_NEW_COMPONENT, payload: payload });
  }

  const handleAddNewSection = () => {
    // setSectionIndex(allSections.length);
    setOpen(true);
  };

  const handleRemoveComponent = async (componentOrder, component_id) => {
    if (component_id) {
      setDialogConfig({
        dialogTitle: "Confirm Delete?",
        type: FORM_ACTION.DELETE_COMPONENT,
        componentData: {
          component_id: component_id,
          componentOrder: componentOrder,
        },
      });
      setModalOpen(true);
    } else {
      let payload = {
        section_id: allSections[sectionIndex]._id,
        componentOrder: componentOrder,
      };
      dispatch({ type: REMOVE_COMPONENT, payload: payload });
    }
  };

  function dialogueClose() {
    setModalOpen(false);
    setDialogConfig({
      dialogTitle: "",
      dialogMessage: "",
      cancelMessage: "",
      agreeMessage: "",
      type: "",
    });
  }

  const handleOnDialogAgree = (type, componentData) => {
    switch (type) {
      case FORM_ACTION.DELETE_COMPONENT:
        deleteComponent(componentData);
        break;
      default:
        toastRef.current.showToastMessage("No such Action Type", "warning");
    }
  };

  async function deleteComponent(componentData) {
    let body = {
      form_id: formConfig._id,
      section_id: allSections[sectionIndex]._id,
      component_id: componentData.component_id,
    };

    let payload = {
      section_id: allSections[sectionIndex]._id,
      componentOrder: componentData.componentOrder,
    };
    setLoading(true);
    await DEL(`${Constants.form}/component`, body)
      .then(async (res) => {
        if (res.data.success) {
          setLoading(false);
          dispatch({ type: REMOVE_COMPONENT, payload: payload });
          dialogueClose();
          toastRef.current.showToastMessage(res.data.message, "success");
        }
      })
      .catch((err) => {
        dialogueClose();
        setLoading(false);
        toastRef.current.showToastMessage(err.message, "error");
      });
  }

  function handleCopyLink() {
    navigator.clipboard.writeText(`${process.env.REACT_APP_STAGING_URL}/${slug}?uniqueid=`);
    toastRef.current.showToastMessage("Form Link Copied.", "success");
  }

  function handleCopyCode() {
    let embedCode = `<iframe src="${process.env.REACT_APP_STAGING_URL}/${slug}?uniqueid=" width="800" height="500" frameborder="0" allowfullscreen> </iframe>`;
    navigator.clipboard.writeText(embedCode);
    toastRef.current.showToastMessage("Embed Code Copied.", "success");
  }

  function onJoiningCodeCopy() {
    navigator.clipboard.writeText(`${slug}`);
    toastRef.current.showToastMessage("Joining Code Copied Successfully.", "success");
  }

  function calculateOrder() {
    if (allSections[sectionIndex]?.component?.length > 0) {
      return allSections[sectionIndex]?.component[allSections[sectionIndex]?.component?.length - 1]
        ?.order;
    } else return 0;
  }

  function handleCopyComponent(componentData) {
    let payload = { ...componentData };
    dispatch({ type: COPY_COMPONENT, payload: payload });
  }

  const handleInputChange = (field, value) => {
    setFormTitleData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  if (Object.keys(loginDetails).length === 0) {
    return "";
  }
  /* --- COMPONENT RETURN --- */
  return (
    <>
      <CreateFormHeader
        onPreview={() =>
          window.open(`${process.env.REACT_APP_STAGING_URL}/${slug}`, "rel=noopener noreferrer")
        }
        onLinkCopy={handleCopyLink}
        onCodeCopy={handleCopyCode}
        onJoiningCodeCopy={onJoiningCodeCopy}
      >
        <FormBanner
          banner={formConfig?.form_banner}
          formTitle={formConfig?.form_title}
          handeFormBannerUpload={handeFormBannerUpload}
          toastRef={toastRef}
        />

        <Grid
          container
          sx={{ paddingY: "1rem", paddingX: "2rem" }}
          flexDirection={"column"}
          gap={"1rem"}
          className="createSectionContainer"
        >
          <Grid item className="createSectionScroll">
            {formConfig?.section?.length > 0 &&
              formConfig?.section?.map((section, i) => (
                <Fragment key={i}>
                  {section.section_title && (
                    <SectionButton
                      key={i}
                      label={
                        section.section_title !== ""
                          ? removeHTMLElement(section.section_title)
                          : "Unnamed Title"
                      }
                      onClick={() => setSectionIndex(i)}
                      disabled={sectionIndex === i ? true : false}
                      bar={formConfig?.section?.length - 1 !== i ? true : false}
                      prevBar={i !== 0 && formConfig?.section[0]?.section_title ? true : false}
                      firstItem={i === 0 ? true : false}
                    />
                  )}
                </Fragment>
              ))}
            <CreateSectionButton onClick={handleAddNewSection} />
            {formConfig?.section?.filter((sec) => Boolean(sec?.section_title))?.length > 1 && (
              <ReorderSectionButton />
            )}
          </Grid>

          <hr className="solid" />
        </Grid>
        {formConfig?.section?.length > 0 &&
          formConfig?.section?.filter((el) => !!el?.section_title)[sectionIndex]?.section_title && (
            <Grid container className="createFormWrapper" flexDirection={"column"}>
              <Grid item lg={6}>
                <SectionTitle
                  handleFormSection={handleFormSection}
                  sectionDetail={allSections[sectionIndex]}
                />
              </Grid>
              {/* {allSections[sectionIndex]?.component.length > 0 &&
            allSections[sectionIndex]?.component.map((component, key) => (
              <FormList
                key={key}
                componentData={component}
                // handleFormMedia={handleFormMedia}
                toastRef={toastRef}
                onDelete={handleRemoveComponent}
                onCopy={handleCopyComponent}
              />
            ))} */}
              <DraggableContent
                sectionIndex={sectionIndex}
                allSections={allSections}
                formId={formConfig._id}
                toastRef={toastRef}
                handleRemoveComponent={handleRemoveComponent}
                handleCopyComponent={handleCopyComponent}
                getFormBySlug={getFormBySlug}
              />
              {allSections[sectionIndex]?.section_title && (
                <FormToolbar
                  handleAddQuestion={() => {
                    handleAddNewComponent(
                      allSections[sectionIndex]._id,
                      calculateOrder(),
                      QUESTION_TYPE,
                    );
                  }}
                  handleAddTitle={() =>
                    handleAddNewComponent(
                      allSections[sectionIndex]._id,
                      calculateOrder(),
                      TITLE_TYPE,
                    )
                  }
                  handleAddImageVideo={() =>
                    handleAddNewComponent(
                      allSections[sectionIndex]._id,
                      calculateOrder(),
                      IMAGE_TYPE,
                    )
                  }
                />
              )}
            </Grid>
          )}
        <ToastNotification ref={toastRef} duration={2000} />
        <DialogBox
          onDialogOpen={modalOpen}
          onDialogClose={dialogueClose}
          dialogTitle={dialogConfig.dialogTitle}
          loading={loading}
          onAgree={() => handleOnDialogAgree(dialogConfig.type, dialogConfig.componentData)}
        />
      </CreateFormHeader>
      <CreateAndEditSectionModal
        open={open}
        onClose={() => {
          setOpen(false);
          setFormTitleData({
            title: "",
            description: "",
            banner: "",
            section_id: "",
          });
        }}
        formTitleData={formTitleData}
        handleInputChange={handleInputChange}
        handleFormSection={handleFormSection}
      />
    </>
  );
};

export default CreateFormLayout;
