import React from "react";
import ComponentContainer from "../../CreateForm/ComponentContainer";
import submission from "../../../Assets/icons/Confirmation.svg";
import { Typography } from "@mui/material";

const SuccessfulSubmission = ({ message }) => {
  return (
    <ComponentContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 1rem",
        }}
      >
        <img src={submission} alt="submission" style={{ height: "15rem", width: "15rem" }} />
        <Typography variant="h6" textAlign={"center"} fontWeight={600}>
          {message}
        </Typography>
      </div>
    </ComponentContainer>
  );
};

export default SuccessfulSubmission;
