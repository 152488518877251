import { Button, Tooltip } from "@mui/material";
import React, { useState } from "react";
import SectionReorderModal from "../../Modals/SectionReorderModal";
import LowPriorityIcon from "@mui/icons-material/LowPriority";

const ReorderSectionButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div className="createReorderSectionWrapper">
        <Tooltip title="Reorder Section" placement="bottom-end">
          <Button
            className="createSectionButton"
            onClick={() => setOpen(true)}
            color="primary"
            sx={{ backgroundColor: "white" }}
          >
            <LowPriorityIcon color="blue" sx={{ fontSize: "1.5rem" }} />
            Reorder
          </Button>
        </Tooltip>
      </div>
      <SectionReorderModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default ReorderSectionButton;
