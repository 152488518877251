import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, ThemeProvider } from "@mui/material";

import { userLogoutAction } from "../../redux/action";
import LogoComponent from "./LogoComponent";
import { THEME } from "../Themes/theme";
import { Constants } from "../../config/Constants";
import { GET } from "../../config/Api";

const DashboardHeader = ({ children }) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let loginDetails = useSelector((state) => state.userLoginDetails);

  const handleLogout = async () => {
    await GET(`${Constants.userLogout}?userID=${loginDetails?.user?.id}`);
    dispatch(userLogoutAction());
    navigate("/");
  };

  return (
    <ThemeProvider theme={THEME}>
      <Grid
        container
        width={"100%"}
        flexDirection={"column"}
        minHeight={"100vh"}
        // sx={{ background: "#FFF9F2" }}
      >
        <Grid
          item
          sx={{
            backgroundImage:
              "linear-gradient(90deg, #141415 0, rgb(0, 75, 87) 40%, rgb(0, 75, 87)70%)",
            zIndex: 999,
          }}
          display={"flex"}
          alignItems={"center"}
          // flexDirection={"row"}
          justifyContent={"space-between"}
          paddingX={"2rem"}
          height={"3.5rem"}
          position="sticky"
          top={0}
        >
          <LogoComponent />
          <Grid item>
            {Object.keys(loginDetails).length > 0 ? (
              <Button variant="contained" onClick={handleLogout} color="primary">
                Logout
              </Button>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    window.location.replace(
                      `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location}`,
                    )
                  }
                  sx={{
                    backgroundColor: "#fff",
                    color: "black",
                    textTransform: "none",
                    fontWeight: 600,
                    "&:hover": {
                      backgroundColor: "#fff", // Keeps the background color the same on hover
                    },
                  }}
                >
                  LogIn
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    window.location.replace(
                      `${process.env.REACT_APP_AUTH_URL_FE}signup?referrer=${window.location}`,
                    )
                  }
                  sx={{
                    backgroundColor: "#ffd370",
                    color: "black",
                    textTransform: "none",
                    fontWeight: 600,
                    "&:hover": {
                      backgroundColor: "#ffd370", // Keeps the background color the same on hover
                    },
                  }}
                >
                  SignUp
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default DashboardHeader;
