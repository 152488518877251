export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  minHeight: 200,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 2,
};
