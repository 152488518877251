import React from "react";
import { FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import {
  QUESTION_CHECKBOX,
  QUESTION_DROPDOWN,
  QUESTION_LINEAR_SCALE,
  QUESTION_RADIO,
} from "../../../config/Constants";

const FormFields = ({
  fieldValue,
  options,
  linearOptions,
  questionData,
  setQuestionData,
  handleEdit,
}) => {
  /* --- METHODS --- */
  const AddOption = (e) => {
    e.preventDefault();
    setQuestionData({
      ...questionData,
      options: [...questionData.options, ""],
    });
    handleEdit();
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...questionData.options];
    updatedOptions.splice(index, 1);

    setQuestionData({
      ...questionData,
      options: updatedOptions,
    });
    handleEdit();
  };

  const handleLinearOptionChange = (e) => {
    let { name, value } = e.target;
    let val1, val2;
    if (name === "label_start_value") {
      val1 = value;
      val2 = linearOptions[linearOptions.length - 1];
    } else if (name === "label_end_value") {
      val1 = linearOptions[0];
      val2 = value;
    }
    let optionsData = [];

    for (let i = val1; i <= val2; i++) {
      optionsData.push(i);
    }
    setQuestionData({ ...questionData, linearOptions: optionsData });
    handleEdit();
  };

  const QTypeIcon = ({ type }) => {
    switch (type) {
      case QUESTION_RADIO:
        return <CircleOutlinedIcon fontSize="small" />;
      case QUESTION_CHECKBOX:
        return <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />;
      default:
        return null;
    }
  };

  switch (fieldValue) {
    case QUESTION_DROPDOWN:
    case QUESTION_RADIO:
    case QUESTION_CHECKBOX:
      /* --- COMPONENT RETURN --- */
      return (
        <>
          {questionData?.options?.length > 0 &&
            questionData.options.map((option, index) => (
              <Grid item display={"flex"} alignItems={"center"} key={index} gap={"0.5rem"}>
                <QTypeIcon type={fieldValue} />
                <TextField
                  key={index}
                  placeholder="New Option"
                  id={`option-${index}`}
                  // label={`Option ${index + 1}`}
                  variant="standard"
                  // fullWidth
                  value={option}
                  onChange={(e) => {
                    handleEdit();

                    setQuestionData((prevQuestionData) => {
                      const updatedOptions = prevQuestionData.options.map((option, i) => {
                        if (i === index) {
                          return e.target.value;
                        }
                        return option;
                      });

                      return {
                        ...prevQuestionData,
                        options: updatedOptions,
                      };
                    });
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <CloseIcon cursor={"pointer"} onClick={() => handleRemoveOption(index)} />
              </Grid>
            ))}
          <Grid
            item
            mt={2}
            display={"flex"}
            gap={"0.25rem"}
            onClick={AddOption}
            sx={{ cursor: "pointer" }}
          >
            <AddIcon />
            <Typography>Add Option</Typography>
          </Grid>
        </>
      );
    case QUESTION_LINEAR_SCALE:
      return (
        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"} spacing={2}>
          <Grid item display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Grid item>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <Select
                  name="label_start_value"
                  value={linearOptions[0]}
                  onChange={handleLinearOptionChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  defaultValue={linearOptions[0]}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                placeholder="Label 1"
                value={questionData.label_start}
                onChange={(e) => {
                  handleEdit();
                  setQuestionData({
                    ...questionData,
                    label_start: e.target.value,
                  });
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography>to</Typography>
          </Grid>
          <Grid item display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Grid item>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <Select
                  name="label_end_value"
                  value={linearOptions[linearOptions.length - 1]}
                  onChange={handleLinearOptionChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                placeholder="Label 2"
                value={questionData.label_end}
                onChange={(e) => {
                  handleEdit();
                  setQuestionData({
                    ...questionData,
                    label_end: e.target.value,
                  });
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    case 7:
      return;
    default:
      return null;
  }
};

export default FormFields;
