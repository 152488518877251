import React from "react";
import DashboardHeader from "../Components/Header/DashboardHeader";
import AdminLayout from "../Components/AdminLayout";

const Admin = () => {
  return (
    <DashboardHeader>
      <AdminLayout />
    </DashboardHeader>
  );
};

export default Admin;
