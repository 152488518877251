import axios from "axios";
import { Constants } from "./Constants";
import { store } from "../redux/store";
import { userLogoutAction } from "../redux/action";

// Token Expiration interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(userLogoutAction());
      window.location = "/";
    } else return Promise.reject(error);
  },
);

export const getToken = async () => {
  const CryptoJS = require("crypto-js");

  const secretKey = "apikey";
  let message = secretKey.concat("__!FM!__", "string_secret_very_is_this");
  // encrypt data
  const encodedToken = CryptoJS.AES.encrypt(message, secretKey).toString();

  const response = await axios.post(Constants.getPreLoginToken, {
    encodedToken,
  });
  return response?.data?.accessToken;
};

export const POST = async (url, body, header) => {
  let { accessToken } = store.getState().userLoginDetails;
  let token;
  if (!accessToken) {
    token = await getToken();
  } else {
    token = accessToken;
  }
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": header ? header : "application/json",
    },
  };
  return await axios.post(url, body, config);
};

export const GET = async (url) => {
  let { accessToken } = store.getState().userLoginDetails;
  let token;
  if (!accessToken) {
    token = await getToken();
  } else {
    token = accessToken;
  }
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return await axios.get(url, config);
};

export const PUT = async (url, body, header) => {
  let { accessToken } = store.getState().userLoginDetails;
  let token;
  if (!accessToken) {
    token = await getToken();
  } else {
    token = accessToken;
  }
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": header ? header : "application/json",
    },
  };
  return await axios.put(url, body, config);
};

export const DEL = async (url, body, header) => {
  let { accessToken } = store.getState().userLoginDetails;
  let token;
  if (!accessToken) {
    token = await getToken();
  } else {
    token = accessToken;
  }

  let headers = {
    authorization: `Bearer ${token}`,
    "Content-Type": header ? header : "application/json",
  };

  return await axios.delete(url, { data: body, headers: headers });
};

export const GETwithoutAuth = async (url) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.get(url, config);
};

export const POSTwithoutAuth = async (url, body, header) => {
  let config = {
    headers: {
      "Content-Type": header ? header : "application/json",
    },
  };
  return await axios.post(url, body, config);
};

export const PUTwithoutAuth = async (url, body, header) => {
  let config = {
    headers: {
      "Content-Type": header ? header : "application/json",
    },
  };
  return await axios.put(url, body, config);
};
