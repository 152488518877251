import { authUrl, baseurl } from "./BaseUrl";

export const Constants = {
  //Login
  userLogin: `${baseurl}/user/login`,
  userLogout: `${authUrl}/v1/auth/logout`,
  //search API's
  userSearch: `${baseurl}/user/search_user?search=`,
  //search API's for approved form craetors
  searchApprovedAndPendingUser: `${baseurl}/user/search_approved_and_pending_user`,
  //Approve form creator API's
  approveFormCreatorRequest: `${baseurl}/user/approve_request`,
  //Deny form creator request
  denyFormCreatorRequest: `${baseurl}/user/deny_request`,
  //get Approved User
  getApprovedUsers: `${baseurl}/user/approved_users`,
  //get Pending User
  getPendingUsers: `${baseurl}/user/pending_users`,
  //PreLoginToken
  getPreLoginToken: `${baseurl}/isAuth`,
  //Forms
  form: `${baseurl}/forms`,
  form_activity: `${baseurl}/form_activity`,
  update_order: `${baseurl}/forms/updateOrder`,
  update_section_order: `${baseurl}/forms/updateFormSectionOrder`,
};

export const QUESTION_TYPE = "question";
export const IMAGE_TYPE = "image";
export const VIDEO_TYPE = "video";
export const TITLE_TYPE = "title";

export const QUESTION_INPUT = "input";
export const QUESTION_TEXT_AREA = "text_area";
export const QUESTION_DROPDOWN = "dropdown";
export const QUESTION_RADIO = "radio";
export const QUESTION_CHECKBOX = "checkbox";
export const QUESTION_LINEAR_SCALE = "linear_scale";
export const QUESTION_FILE = "file";

export const INPUT_CALENDAR = "calendar";
export const INPUT_TIME = "time";

export const QuestionTypes = [
  { index: 1, key: QUESTION_INPUT, value: "Text Input" },
  { index: 2, key: QUESTION_TEXT_AREA, value: "Text Paragraph Input" },
  { index: 3, key: QUESTION_DROPDOWN, value: "Dropdown" },
  { index: 4, key: QUESTION_RADIO, value: "MCQ Single Select" },
  { index: 5, key: QUESTION_CHECKBOX, value: "MCQ Multi Select" },
  { index: 6, key: QUESTION_LINEAR_SCALE, value: "Linear Scale" },
  // { index: 7, key: QUESTION_FILE, value: "File" },
];
