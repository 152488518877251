import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateForm from "./page/createForm";
import Dashboard from "./page/dashboard";

import "./App.scss";
import Home from "./page/home";
import Form from "./page/form";
import Setting from "./page/setting";
import Admin from "./page/admin";
import NotFound from "./Components/NotFound/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/create/:slug" element={<CreateForm />} />
        <Route path="/setting/:slug" element={<Setting />} />
        <Route path="/:slug" element={<Form />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
