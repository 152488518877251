import React from "react";
import "./FormComponent.scss";

const FullwidthButton = ({ label, dragHandleProps, isDragging, sectionOrder }) => {
  return (
    <div className="sectionReorderButtonWrapper">
      <div>{sectionOrder}</div>
      <div
        dragHandleProps={dragHandleProps}
        className={`sectionReorderButton ${isDragging && "buttonBorder"}`}
      >
        {label}
      </div>
    </div>
  );
};

export default FullwidthButton;
