import React from "react";
import ComponentContainer from "../../CreateForm/ComponentContainer";

const FormTitlePreview = ({ title, description }) => {
  return (
    <ComponentContainer>
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </ComponentContainer>
  );
};

export default FormTitlePreview;
