import styled from "@emotion/styled";
import { FormControl, InputBase, Select } from "@mui/material";

const DropdownInputMainStyle = styled(InputBase)(({ theme }) => ({
  "label + &": {
    // marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    color: "black",
    padding: "1rem",
    // padding: ".5rem",
    paddingLeft: "1.2rem",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["'IBM Plex Sans', sans-serif"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const Dropdown = ({
  name,
  value,
  onChange,
  renderValue,
  errorMsg,
  children,
  disabled,
  style,
  className,
  styleType,
}) => {
  return (
    <>
      <FormControl fullWidth>
        <Select
          name={name}
          value={value}
          onChange={onChange}
          renderValue={renderValue}
          input={<DropdownInputMainStyle />}
          disabled={disabled}
          sx={{ width: "100%", ...style }}
          classes={className}
          displayEmpty
        >
          {children}
        </Select>
      </FormControl>
      {errorMsg && <label className="errorMsg">{errorMsg}</label>}
    </>
  );
};
