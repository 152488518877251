// import React from "react";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import { Typography, Divider, Grid } from "@mui/material";

// function UserRequestDetailsModal({ open, setOpen, user }) {
//   return (
//     <Dialog
//       open={open}
//       onClose={() => setOpen(false)}
//       sx={{
//         "& .MuiDialog-paper": { width: "50%", maxWidth: "none", height: "80vh" },
//       }}
//     >
//       <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//         <Typography
//           variant="h6"
//           component="div"
//           sx={{ fontWeight: 600, flexGrow: 1, textAlign: "center" }}
//         >
//           User Details
//         </Typography>
//         <IconButton onClick={() => setOpen(false)} sx={{ marginLeft: "auto" }}>
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>

//       <Divider />
//       <DialogContent>
//         <DialogContentText>
//           <Grid
//             container
//             sx={{ paddingY: "1rem", paddingX: "2rem" }}
//             flexDirection={"column"}
//             gap={"1rem"}
//           >
//             <Grid item>
//               <b>UniqueId</b> : {user.email}
//             </Grid>
//             <Grid item>
//               <b>Email</b> : {user.reset_email}
//             </Grid>
//             <Grid item>
//               <b>Name </b>: {user.name}
//             </Grid>
//             <Grid item>
//               <b>Organization</b> : {user.job.org}
//             </Grid>
//             <Grid item>
//               <b>Country</b> : {user.country}
//             </Grid>
//             <Grid item>
//               <b>State</b> : {user.state}
//             </Grid>
//             <Grid item>
//               <b>Purpose</b> : {user.purpose}
//             </Grid>
//           </Grid>
//         </DialogContentText>
//       </DialogContent>
//     </Dialog>
//   );
// }

// export default UserRequestDetailsModal;

import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";

function UserRequestDetailsModal({ open, setOpen, user }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      sx={{
        "& .MuiDialog-paper": {
          width: fullScreen ? "100%" : "50%",
          height: fullScreen ? "100%" : "80vh",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, flexGrow: 1, textAlign: "center" }}
        >
          User Details
        </Typography>
        <IconButton onClick={() => setOpen(false)} sx={{ marginLeft: "auto" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider />
      <DialogContent>
        <DialogContentText>
          <Grid
            container
            sx={{ paddingY: "1rem", paddingX: "2rem" }}
            flexDirection={"column"}
            gap={"1rem"}
          >
            <Grid item>
              <b>UniqueId</b> : {user.email}
            </Grid>
            <Grid item>
              <b>Email</b> : {user.reset_email}
            </Grid>
            <Grid item>
              <b>Name</b> : {user.name}
            </Grid>
            <Grid item>
              <b>Organization</b> : {user.job?.org}
            </Grid>
            <Grid item>
              <b>Country</b> : {user.country}
            </Grid>
            <Grid item>
              <b>State</b> : {user.state}
            </Grid>
            <Grid item>
              <b>Purpose</b> : {user.purpose}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default UserRequestDetailsModal;
