import React from "react";
import DashboardLayout from "../Components/Dashboard";
import DashboardHeader from "../Components/Header/DashboardHeader";

const Dashboard = () => {
  return (
    <div>
      <DashboardHeader>
        <DashboardLayout />
      </DashboardHeader>
    </div>
  );
};

export default Dashboard;
