import React from "react";
import FormCreatorRequestCard from "../Card/index";
import { Alert, Box, Pagination, TextField } from "@mui/material";
import SkeletonLoader from "../Loader/loading";

const PendingUsers = ({
  pendingUsers,
  currentPage,
  totalPages,
  handlePageChange,
  search,
  handleSearchChange,
  loading,
  handleApproveRequest,
  handleDenyRequest,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Search user by Unique Id, Name & Email..."
          placeholder="Search user by Unique Id, Name & Email..."
          variant="outlined"
          fullWidth
          margin="normal"
          value={search}
          autoFocus
          onChange={handleSearchChange}
        />
      </Box>

      {loading && pendingUsers.length === 0 ? (
        <SkeletonLoader count={5} width={100} height={75} />
      ) : (
        <>
          {pendingUsers.length > 0 ? (
            <>
              {pendingUsers.map((user) => (
                <FormCreatorRequestCard
                  key={user._id}
                  user={user}
                  buttonText="Approve"
                  handleDenyRequest={() => handleDenyRequest(user._id)}
                  handleApproveRequest={() => handleApproveRequest(user._id)}
                  color={"primary"}
                />
              ))}
              {totalPages > 1 && (
                <Box
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    paddingBottom: "10px",
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    sx={{
                      "& .MuiPaginationItem-page.Mui-selected": {
                        backgroundColor: "#9c27b0",
                        color: "#fff",
                      },
                    }}
                  />
                </Box>
              )}
            </>
          ) : (
            !loading && (
              <Alert severity="warning" sx={{ width: "50%", textAlign: "center" }}>
                No new pending requests are available.
              </Alert>
            )
          )}
        </>
      )}
    </Box>
  );
};

export default PendingUsers;
