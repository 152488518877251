import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Dropdown } from "./Dropdown";
import {
  QUESTION_CHECKBOX,
  QUESTION_DROPDOWN,
  QUESTION_FILE,
  QUESTION_INPUT,
  QUESTION_LINEAR_SCALE,
  QUESTION_RADIO,
  QUESTION_TEXT_AREA,
} from "../../../config/Constants";

const QuestionFields = ({
  qType,
  component_id,
  linearScaleLabels,
  options,
  questionData,
  handleFormChange,
  handleMultiChoiceChange,
}) => {
  switch (qType) {
    case QUESTION_INPUT:
    case QUESTION_TEXT_AREA:
      return (
        <TextField
          label="Your Answer"
          variant="standard"
          fullWidth
          multiline={qType === QUESTION_TEXT_AREA ? true : false}
          rows={3}
          name={component_id}
          value={questionData[component_id]?.response ? questionData[component_id]?.response : ""}
          onChange={(e) => handleFormChange(e, qType)}
          InputProps={{
            disableUnderline: true,
          }}
        />
      );
    case QUESTION_DROPDOWN:
      return (
        <Dropdown
          name={component_id}
          value={questionData[component_id]?.response || ""}
          onChange={(e) => handleFormChange(e, qType)}
          renderValue={(selected) => {
            if (!selected) {
              return <span className="dropdownLabel">Select an option</span>;
            } else {
              // let selectedOption = options.find((item) => item._id === selected);
              return <span>{selected}</span>;
            }
          }}
        >
          {options.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </Dropdown>
      );
    case QUESTION_RADIO:
      return (
        <FormControl>
          <RadioGroup
            name={component_id}
            value={questionData[component_id] || ""}
            onChange={(e) => handleFormChange(e, qType)}
          >
            {options.map((option, i) => (
              <FormControlLabel
                key={i}
                value={option.value}
                control={
                  <Radio
                    checked={option.value === questionData[component_id]?.response ? true : false}
                  />
                }
                label={option.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    case QUESTION_CHECKBOX:
      return options.map((option, i) => (
        <FormControlLabel
          key={i}
          control={
            <Checkbox
              name={component_id}
              checked={questionData[component_id]?.response.includes(option.value) || false}
              value={option.value || ""}
              onChange={(e) => handleMultiChoiceChange(e, qType)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={option.value || ""}
        />
      ));
    case QUESTION_FILE:
      return (
        <FormGroup>
          {options.map((option, i) => (
            <FormControlLabel key={i} control={<Checkbox />} label={option?.value} />
          ))}
        </FormGroup>
      );
    case QUESTION_LINEAR_SCALE:
      return (
        <>
          <Grid
            item
            display={"flex"}
            gap={"0.5rem"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {linearScaleLabels?.label_start && (
              <p style={{ fontWeight: "bold" }}>{linearScaleLabels?.label_start}</p>
            )}
            <FormControl>
              <RadioGroup
                name={component_id}
                value={questionData[component_id] || ""}
                onChange={(e) => handleFormChange(e, qType)}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                {options.map((option, i) => (
                  <FormControlLabel
                    key={i}
                    value={option.value}
                    control={
                      <Radio
                        checked={
                          option.value === questionData[component_id]?.response ? true : false
                        }
                      />
                    }
                    label={option.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {linearScaleLabels?.label_end && (
              <p style={{ fontWeight: "bold" }}>{linearScaleLabels?.label_end}</p>
            )}
          </Grid>
        </>
      );
    default:
      return null;
  }
};

export default QuestionFields;
