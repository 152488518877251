import React from "react";
import "./FormComponent.scss";
import { Button } from "@mui/material";

const SectionButton = ({
  label,
  onClick,
  disabled,
  bar,
  prevBar,
  firstItem,
}) => {
  return (
    <div className="sectionButtonWrapper">
      {prevBar && (
        <div className="sectionConnector">
          <hr />
        </div>
      )}
      <Button
        onClick={onClick}
        style={{ marginLeft: firstItem && "3.4rem", width: "100%" }}
        className={`sectionButton ${disabled && "buttonBorder"}`}
        sx={{ textTransform: "none" }}
      >
        {label.length > 9 ? label?.substring(0, 9) + "..." : label}
      </Button>
      {bar && (
        <div className="sectionConnector">
          <hr />
        </div>
      )}
    </div>
  );
};

export default SectionButton;
