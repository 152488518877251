import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogBox = ({
  onDialogOpen,
  onDialogClose,
  onAgree,
  dialogTitle,
  dialogMessage,
  cancelMessage,
  agreeMessage,
  loading,
}) => {
  return (
    <Dialog
      open={onDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onDialogClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          minWidth: "300px",
        },
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      {dialogMessage && (
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{dialogMessage}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onDialogClose} color="red">
          {" "}
          {cancelMessage ? cancelMessage : "Cancel"}
        </Button>
        <Button onClick={onAgree} variant="contained" color="green" disabled={loading}>
          {agreeMessage ? agreeMessage : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
