import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, Divider } from "@mui/material";
import FSTextEditor from "../CreateForm/FormComponents/FSTextEditor";

function CreateAndEditSectionModal({
  open,
  onClose,
  isEdit = false,
  formTitleData,
  handleInputChange,
  handleFormSection,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ "& .MuiDialog-paper": { width: "50%", maxWidth: "none" } }}
    >
      <DialogTitle padding={"0rem 0rem"}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: 1, textAlign: "center", fontWeight: 600 }}
          >
            {isEdit ? "Edit" : "Create"} Section
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => onClose(false)}
            aria-label="close"
            sx={{ backgroundColor: "#e4e6eb" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6" ml={3} fontWeight={600}>
            Section Title
          </Typography>
          <FSTextEditor
            placeholder={"Section Title"}
            value={formTitleData?.title || ""}
            onChange={(newValue) => handleInputChange("title", newValue)}
          />
          <Typography variant="h6" ml={3} fontWeight={600} mt={2}>
            Section Description
          </Typography>
          <FSTextEditor
            value={formTitleData?.description || ""}
            placeholder={"Section Description"}
            onChange={(newValue) => handleInputChange("description", newValue)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(false)}
          color="primary"
          sx={{ textTransform: "none", padding: "0.3rem 1.5rem", fontWeight: 600 }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            handleFormSection(formTitleData);
            onClose();
          }}
          color="primary"
          variant="contained"
          sx={{ textTransform: "none", padding: "0.3rem 1.5rem", fontWeight: 600 }}
        >
          Save Section
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateAndEditSectionModal;
