import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="xl"
      sx={{
        width: "97%",
        py: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "85vh",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h1" sx={{ fontWeight: "bold" }}>
          Oops!
        </Typography>
        <Typography variant="h5">404 - Page Not Found</Typography>
        <Typography sx={{ margin: "1rem 0" }}>
          The page you are looking for might have been removed, had its name changed, or is
          temporarily unavailable.
        </Typography>
        <Button
          variant="contained"
          sx={{ borderRadius: "20px", textTransform: "none" }}
          onClick={() => navigate("/")}
        >
          GO TO HOME PAGE
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
