import React from "react";
import { FormControlLabel, Grid, Switch, Tooltip } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ButtonMain from "../../Buttons/ButtonMain";

const FormAction = ({
  checked,
  handleRequired,
  onCopy,
  onDelete,
  isRequiredVisible = false,
  onSave,
  saveVisible,
  saveDisabled,
  hideDuplicate,
}) => {
  return (
    <Grid container>
      <hr className="solid" />
      <Grid item className="formActionComponents">
        <Grid
          item
          className="actionWrapper"
          justifyContent={saveVisible ? "space-between" : "flex-end"}
        >
          <Grid item className="actionItems">
            {hideDuplicate && (
              <Tooltip title="Duplicate" placement="top-start">
                <ContentCopyOutlinedIcon sx={{ cursor: "pointer" }} onClick={onCopy} />
              </Tooltip>
            )}
            <Tooltip title="Delete" placement="top-start">
              <DeleteOutlineOutlinedIcon
                sx={{ cursor: "pointer", fill: "red" }}
                color={"green"}
                onClick={onDelete}
              />
            </Tooltip>
            {isRequiredVisible && (
              <FormControlLabel
                control={
                  <Switch
                    // disabled={true}
                    checked={checked}
                    onChange={handleRequired}
                    inputProps={{ "aria-label": "controlled" }}
                    label="Required"
                  />
                }
                label="Required"
              />
            )}
            {saveVisible && (
              <ButtonMain onClick={onSave} disabled={saveDisabled}>
                Save
              </ButtonMain>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormAction;
