import React, { useEffect, useRef, useState } from "react";
import { Button, Grid } from "@mui/material";

import "../Form.scss";

import ComponentContainer from "../ComponentContainer";
import FormAction from "./FormAction";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import { Constants, IMAGE_TYPE } from "../../../config/Constants";
import { POST, PUT } from "../../../config/Api";

const ImageContainer = ({ onDelete, componentData, toastRef, getFormBySlug }) => {
  /* --- HOOKS --- */
  const fileInputRef = useRef(null);
  /* --- STATES --- */
  const [mediaLocal, setMediaLocal] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isEdit, setIsEdit] = useState(true);
  const [imageData, setImageData] = useState({ media_url: "", form_id: "", section_id: "" });

  /* --- LIFE CYCLES --- */

  useEffect(() => {
    if (componentData) {
      setIsEdit(false);
      setImageData({
        _id: componentData._id,
        form_id: componentData.form_id,
        section_id: componentData.section_id,
        media_url: componentData.media_url,
        order: componentData.order,
      });
      // if (componentData.media_url === "") {
      //   handleImageUpload();
      // }
    }
  }, [componentData]);

  /* --- METHODS --- */

  const handleImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the uploaded file is an image (image/* mime type)
      if (file.type.startsWith("image/")) {
        // Check if the file size is within the specified limit (2MB)
        if (file.size <= 2 * 1024 * 1024) {
          const imageURL = URL.createObjectURL(file);
          setMediaLocal(imageURL);
          setIsEdit(true);
          setSelectedMedia(file);
        } else {
          // Handle file size validation error
          toastRef.current.showToastMessage(
            "Please select an image file smaller than 2MB.",
            "warning",
          );
        }
      } else {
        // Handle file type validation error
        toastRef.current.showToastMessage("Please select a valid image file.", "warning");
      }
    }
  };

  const saveImageUpload = async (e) => {
    e.preventDefault();
    if (selectedMedia) {
      setIsEdit(false);
      let formData = new FormData();
      formData.append("component_id", imageData._id);
      formData.append("form_id", imageData.form_id);
      formData.append("section_id", imageData.section_id);
      formData.append("type", IMAGE_TYPE);
      formData.append("order", imageData.order);
      formData.append("file", selectedMedia);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      if (imageData._id) {
        PUT(`${Constants.form}/component`, formData, config)
          .then((res) => {
            setIsEdit(true);
            getFormBySlug();
            toastRef.current.showToastMessage(res.data.message, "success");
          })
          .catch((err) => {
            setIsEdit(true);
            toastRef.current.showToastMessage(err.message, "error");
          });
      } else {
        POST(`${Constants.form}/component`, formData, config)
          .then((res) => {
            getFormBySlug();
            setImageData({ ...imageData, _id: res.data.config_id });
            toastRef.current.showToastMessage(res.data.message, "success");
          })
          .catch((err) => {
            setIsEdit(true);
            toastRef.current.showToastMessage(err.message, "error");
          });
      }
    } else {
      toastRef.current.showToastMessage("Please select an image.", "warning");
    }
  };

  /* --- COMPONENT RETURN --- */
  return (
    <ComponentContainer>
      <Grid item style={{ width: "100%" }}>
        <input
          id="image"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRef}
          inputProps={{ accept: "image/*" }}
        />

        {/* Image preview */}
        <Grid item className="formImageContainer">
          {mediaLocal || imageData?.media_url ? (
            <img
              src={mediaLocal || `${cloudfrontUrl}/${imageData?.media_url}`}
              alt="Uploaded Preview"
              className="formImagePreview"
              onClick={handleImageUpload}
            />
          ) : (
            <Button variant="outlined" onClick={handleImageUpload}>
              Upload Image
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        justifyContent={!isEdit ? "space-between" : "flex-end"}
        width={"100%"}
      >
        <FormAction
          saveVisible={true}
          saveDisabled={isEdit ? false : true}
          onSave={saveImageUpload}
          onCopy={""}
          onDelete={() => {
            onDelete(imageData.order, imageData?._id);
          }}
          hideDuplicate={false}
        />
      </Grid>
    </ComponentContainer>
  );
};

export default ImageContainer;
