import React, { useRef, useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { styled } from "@mui/material/styles";

import "./addcoeditor.scss";
import { DEL, GET } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { ToastNotification } from "../ToastNotification";
import ButtonMain from "../Buttons/ButtonMain";
import SearchBar from "../SearchBar";

const StyledBackdrop = styled("div")({
  backdropFilter: "blur(10px)",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
});

const AddCoeditorModal = ({ showCoeditorModal, setShowCoeditorModal, title }) => {
  const [membersList, setMembersList] = useState([]);
  const [creatorData, setCreatorData] = useState({});
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();

  useEffect(() => {
    setLoading(true);
    //get member list by course id
    showCoeditorModal?.open &&
      GET(`${Constants.form}/get_form_members?form_id=${showCoeditorModal?.form_id}`)
        .then((res) => {
          setLoading(false);
          setMembersList(res.data.members);
          setCreatorData(res.data.creator);
        })
        .catch((err) => {
          setLoading(false);
          setMembersList([]);
        });
  }, [showCoeditorModal?.open, showCoeditorModal?.form_id]);

  const handleRemoveMember = (member) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("user_id", member.user._id);
    formData.append("form_id", showCoeditorModal?.form_id);
    DEL(`${Constants.form}/remove_member`, formData)
      .then((res) => {
        setLoading(false);
        toastRef.current.showToastMessage(res?.data?.message, "success");
        setMembersList((prevList) => prevList.filter((m) => m.user._id !== member.user._id));
      })
      .catch(() => {
        setLoading(false);
        toastRef.current.showToastMessage("Could not remove member.", "error");
      });
  };

  const handleClose = () => {
    setShowCoeditorModal((prev) => ({
      ...prev,
      open: false,
    }));
    setMembersList([]);
  };

  return (
    <React.Fragment>
      <Dialog
        open={showCoeditorModal.open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        maxWidth="sm"
        fullWidth
        BackdropProps={{
          component: StyledBackdrop,
        }}
      >
        <DialogTitle>Add Co-editor</DialogTitle>
        <DialogContent>
          <DialogContentText>Add Co-editor</DialogContentText>
          <SearchBar showCoeditorModal={showCoeditorModal} setMembersList={setMembersList} />
          {loading && (
            <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <List>
            <ListItem disablePadding>
              <ListItemIcon>
                <Box
                  height={40}
                  width={40}
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  bgcolor={"#ffffff"}
                  borderRadius={"50%"}
                >
                  {creatorData?.initials}
                </Box>
              </ListItemIcon>
              <ListItemText primary="Created By" secondary={`${creatorData.email}`} />
            </ListItem>
          </List>
          <List
            sx={{ width: "100%", overflow: "auto", maxHeight: "400px" }}
            subheader={
              <ListSubheader component="div">
                <Typography variant="body1" component={"strong"}>
                  {title || "Members"}
                </Typography>
              </ListSubheader>
            }
          >
            {membersList &&
              membersList.map(
                (member) =>
                  member?.permit_val !== "inactive" && (
                    <ListItem
                      key={member?.user?.email}
                      className="margin-top-2 bg-white"
                      disablePadding
                    >
                      <ListItemButton dense>
                        <ListItemIcon>
                          <Box
                            height={40}
                            width={40}
                            display="flex"
                            alignItems={"center"}
                            justifyContent="center"
                            bgcolor={"#f5f5f5"}
                            borderRadius={"50%"}
                          >{`${member?.user?.initials}`}</Box>
                        </ListItemIcon>
                        <ListItemText primary={`${member?.user?.email}`} />
                        {member?.permit_val === "moderator" ? (
                          <Chip
                            label="Moderator"
                            size="small"
                            className="moderator_badge"
                            color="info"
                            variant="contained"
                          />
                        ) : (
                          <Chip
                            label="Facilitator"
                            size="small"
                            className="facilitator_badge"
                            color="info"
                            variant="contained"
                          />
                        )}
                        <IconButton
                          edge="end"
                          color="default"
                          onClick={() => handleRemoveMember(member)}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </ListItemButton>
                    </ListItem>
                  ),
              )}
          </List>
        </DialogContent>
        <DialogActions>
          <ButtonMain onClick={handleClose}>Close</ButtonMain>
        </DialogActions>
      </Dialog>
      <ToastNotification ref={toastRef} duration={4000} />
    </React.Fragment>
  );
};

export default AddCoeditorModal;
