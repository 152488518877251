import { createTheme } from "@mui/material";

export const THEME = createTheme({
  typography: {
    allVariants: {
      color: "#2A2A2A",
    },
    textTransform: "none",
    fontFamily: "'Source Sans 3', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    button: {
      color: "#fff",
    },
  },
  palette: {
    primary: {
      main: "#F48221",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
    },
    formGreen: {
      main: "#004B57",
      contrastText: "#fff",
    },
    blue: {
      main: "rgb(62, 191, 246)",
    },
    red: {
      main: "#f7544c",
    },
    green: {
      main: "#004B57",
      contrastText: "#fff",
    },
  },
});
