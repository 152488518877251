import React from "react";
import { Box } from "@mui/material";
import ComponentContainer from "../../CreateForm/ComponentContainer";
import QuestionFields from "./QuestionFields";
import "../formPreview.scss";
import QuestionTitle from "./QuestionTitle";
import { QUESTION_DROPDOWN } from "../../../config/Constants";

const QuestionPreview = ({
  component_id,
  question,
  questionData,
  setQuestionData,
  handleFormChange,
  handleMultiChoiceChange,
}) => {
  return (
    <>
      <Box className="previewQuestion">
        <QuestionTitle title={question.qTitle} required={question?.is_required} />
        <ComponentContainer padding={question.qType === QUESTION_DROPDOWN ? 0 : 2}>
          <QuestionFields
            qType={question.qType}
            component_id={component_id}
            linearScaleLabels={question.linear_scale_label}
            options={question.options}
            questionData={questionData}
            setQuestionData={setQuestionData}
            handleFormChange={handleFormChange}
            handleMultiChoiceChange={handleMultiChoiceChange}
          />
        </ComponentContainer>
      </Box>
    </>
  );
};

export default QuestionPreview;
